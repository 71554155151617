import { PatientAccessDTO } from '../types/data';

const accessTokenKey = 'checkpoint-token';
const passcodeKey = 'checkpoint-passcode';

export const storePatientCredentials = ({ accessToken, passcode }: PatientAccessDTO) => {
  sessionStorage.setItem(accessTokenKey, accessToken);
  sessionStorage.setItem(passcodeKey, passcode);
};

export const loadAccessToken = () => sessionStorage.getItem(accessTokenKey);
export const loadPasscode = () => sessionStorage.getItem(passcodeKey);

export const loadPatientCredentials = () => {
  const credentials = {
    accessToken: loadAccessToken(),
    passcode: loadPasscode(),
  };
  return credentials.accessToken && credentials.passcode ? (credentials as PatientAccessDTO) : null;
};

export const invalidateStoredPasscode = () => sessionStorage.removeItem(passcodeKey);
