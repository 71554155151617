import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import de from './de.json';
import en from './en.json';

const setupTranslations = () =>
  i18n
    .use(detector)
    .use(initReactI18next)
    .init({
      resources: {
        de,
        en,
      },
      supportedLngs: ['de', 'en'],
      fallbackLng: 'de',
      interpolation: {
        escapeValue: false,
      },
      parseMissingKeyHandler: (key: string) => {
        console.error(key);
        return key;
      },
    });

setupTranslations();

export default i18n;
