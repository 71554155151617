import { createReducer } from 'deox';
import { AlertSeverity } from '../../types/alert';
import { addAlert, removeAlert } from './action';
import produce from 'immer';

const initialState: Readonly<{
  alerts: Array<{
    id: string;
    messageKey: string;
    severity: AlertSeverity;
    duration?: number;
  }>;
}> = {
  alerts: [],
};

const SUCCESS_ALERT_DURATION = 5000;

export const alertReducer = createReducer(initialState, (handleAction) => [
  handleAction(addAlert, (state, { payload: alert }) =>
    produce(state, (draft) => {
      draft.alerts.push({
        ...alert,
        duration: alert.duration ?? (alert.severity === AlertSeverity.SUCCESS ? SUCCESS_ALERT_DURATION : undefined),
      });
    })
  ),
  handleAction(removeAlert, (state, { payload: { id } }) =>
    produce(state, (draft) => {
      draft.alerts = state.alerts.filter((alert) => alert.id !== id);
    })
  ),
]);
