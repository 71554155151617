import { call, put, takeLatest } from 'redux-saga/effects';
import { getType } from 'deox';
import { requestActionBundles, setUsers } from './action';
import { endpoints } from '../../common/api/api';
import { getApiClientForMethod } from '../../common/api/request-builder';
import { AxiosResponse } from 'axios';
import { getAuthorizationHeader } from '../../common/api/getAuthHeader';
import { getToken } from '../../common/keycloak';
import {
  logWarningIfValidationRemarksArePresent,
  transformWithSchema,
} from '@healthinal/typescript-schema-transformer';
import { userDTOListSchema } from '../../types/schemas';
import { addAlert } from '../alert/action';
import { v4 as uuid } from 'uuid';
import { AlertSeverity } from '../../types/alert';

function* requestSaga() {
  try {
    const { method, url } = endpoints.users.readAllWithRole();
    const result: AxiosResponse = yield call(getApiClientForMethod(method), url, {
      params: {
        role: 'internal-employee',
      },
      headers: getAuthorizationHeader(yield call(getToken)),
    });
    const [payload, validationRemarks] = transformWithSchema(userDTOListSchema, result.data);
    logWarningIfValidationRemarksArePresent('user read all saga', validationRemarks);
    yield put(setUsers(payload.items));
    yield put(requestActionBundles.readAllInternalUsers.requestSucceeded());
  } catch (error) {
    console.log(error);
    const id: string = yield call(uuid);
    yield put(addAlert(id, 'orderForm.userLoadError', AlertSeverity.ERROR));
    yield put(
      requestActionBundles.readAllInternalUsers.requestFailed({
        id,
        message: JSON.stringify(error),
      })
    );
  }
}

export function* usersReadSaga() {
  yield takeLatest(getType(requestActionBundles.readAllInternalUsers.sendRequest), requestSaga);
}
