import MainNav from '../app-bar/MainNav';
import { Container, makeStyles, Theme } from '@material-ui/core';
import { keycloak } from '../../common/keycloak';
import LanguageRadioGroup from '../order-form/LanguageRadioGroup';
import ChecksCheckboxGroup from '../order-form/ChecksCheckboxGroup';
import InfectionCheckboxGroup from '../order-form/InfectionCheckboxGroup';
import SecondaryButton from '../buttons/SecondaryButton';
import { useNavigate, useParams } from 'react-router-dom';
import PrimaryButton from '../buttons/PrimaryButton';
import { useEffect } from 'react';
import { AlertSeverity } from '../../types/alert';
import { v4 as uuid } from 'uuid';
import PhoneField from '../order-form/PhoneField';
import KgNrField from '../order-form/KgNrField';
import { Information } from 'mdi-material-ui';
import { useTranslation } from 'react-i18next';
import { Props } from './OrderUpdateContainer';
import { RoutePaths } from '../router/Router';
import UserAssignmentFieldContainer from '../order-form/UserAssignmentFieldContainer';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(3.5),
  },
  formBlock: {
    marginTop: theme.spacing(4),
  },
  hint: {
    marginTop: theme.spacing(2),
    fontSize: theme.typography.pxToRem(11),
    display: 'flex',
  },
  infoIcon: {
    fontSize: theme.typography.pxToRem(18),
    marginRight: theme.spacing(0.75),
  },
  actions: { marginTop: theme.spacing(2), display: 'flex', justifyContent: 'end', gap: theme.spacing(1) },
}));

export default function OrderUpdate({
  isValid,
  isUpdatingOrder,
  isLoadingPagedOrders,
  formState,
  infectionsAlreadyProcessed,
  selectOrder,
  setOrderToBeUpdated,
  setPhone,
  setInfections,
  setLanguage,
  addAlert,
  updateOrder,
}: Props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { id } = useParams();

  const orderToBeUpdated = selectOrder(id || '');
  const [t] = useTranslation();

  useEffect(() => {
    if (orderToBeUpdated) {
      setOrderToBeUpdated(orderToBeUpdated);
    } else if (!isLoadingPagedOrders) {
      navigate(RoutePaths.ORDER_OVERVIEW);
      addAlert(uuid(), 'orderUpdate.notFoundError', AlertSeverity.ERROR);
    }
  }, [orderToBeUpdated, addAlert, setOrderToBeUpdated, isLoadingPagedOrders, navigate]);

  return (
    <>
      <MainNav loggedIn={true} doctorView={true} onLogout={keycloak.logout} />
      {orderToBeUpdated && (
        <Container maxWidth={'lg'} className={classes.container}>
          <KgNrField kgNrField={formState.kgNr} disabled />
          <PhoneField phoneField={formState.phone} setPhone={setPhone} />
          <div className={classes.hint}>
            <Information className={classes.infoIcon} /> <span>{t('orderUpdate.phoneInfoText')}</span>
          </div>
          <div className={classes.formBlock}>
            <ChecksCheckboxGroup formField={formState.infections} disabled={true} />
          </div>
          <div className={classes.formBlock}>
            <InfectionCheckboxGroup
              formField={formState.infections}
              infectionsAlreadyProcessed={infectionsAlreadyProcessed}
              setValues={setInfections}
            />
          </div>
          <div className={classes.formBlock}>
            <LanguageRadioGroup formField={formState.language} setLanguage={setLanguage} />
          </div>
          <div className={classes.formBlock}>
            <UserAssignmentFieldContainer assignedEmployeeId={formState.assignedEmployeeId.value} disabled={true} />
          </div>
          <div className={classes.actions}>
            <SecondaryButton translationKey={'orderUpdate.cancelButton'} onClick={() => navigate('/admin/')} />
            <PrimaryButton
              translationKey={'orderUpdate.updateButton'}
              onClick={() => id && updateOrder(id)}
              disabled={!isValid}
              isLoading={isUpdatingOrder(id || '')}
            />
          </div>
        </Container>
      )}
    </>
  );
}
