import React, { useEffect, useRef } from 'react';
import { Props } from './AppAlertsContainer';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core';
import { AlertSeverity } from '../../types/alert';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  snackbarContent: {
    '& p': {
      margin: 0,
    },
    '& a': {
      color: 'inherit',
    },
  },
  snackbarError: {
    background: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  snackbarSuccess: {
    background: theme.palette.success.main,
    color: theme.palette.success.contrastText,
  },
}));

const handleOnClick = (event: React.MouseEvent) => {
  // if the user clicked the link inside the alert we retry the operation by reloading the page
  if ((event.target as HTMLElement)?.tagName === 'A') {
    const a = event.target as HTMLLinkElement;
    // check to see if link is empty (same url as current location)
    if (a.href === window.location.origin + window.location.pathname) {
      event.preventDefault();
      window.location.reload();
    }
  }
};

const AppAlerts = ({ alerts, removeAlert }: Props) => {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const alertsRef = useRef(alerts);
  const [t] = useTranslation();

  useEffect(() => {
    if (alerts.length > alertsRef.current.length) {
      const { messageKey, id, duration, severity } = alerts[alerts.length - 1];
      enqueueSnackbar(<span className={classes.snackbarContent}>{t(messageKey)}</span>, {
        autoHideDuration: duration ?? null,
        key: id,
        variant: severity,
        onExited: () => removeAlert(id),
        onClick: (event) => {
          handleOnClick(event);
          closeSnackbar(id);
        },
        className: severity === AlertSeverity.SUCCESS ? classes.snackbarSuccess : classes.snackbarError,
      });
    }
    alertsRef.current = alerts;
  }, [
    alerts,
    classes.snackbarContent,
    classes.snackbarSuccess,
    classes.snackbarError,
    enqueueSnackbar,
    closeSnackbar,
    removeAlert,
    t,
  ]);

  return null;
};

export default AppAlerts;
