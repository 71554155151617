import { RootState } from '../../store';
import { Action, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { selectIsAdminLoggedIn, selectIsInternalEmployee } from '../../store/admin-login/selector';
import Router from './Router';

const mapStateToProps = (state: RootState) => ({
  isInternalEmployee: selectIsInternalEmployee(state),
  isAdminLoggedIn: selectIsAdminLoggedIn(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({});

type OwnProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
export type Props = Readonly<OwnProps & StateProps & DispatchProps>;

export default connect(mapStateToProps, mapDispatchToProps)(Router);
