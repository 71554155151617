import { connect } from 'react-redux';
import { InterpretationMessageDialog } from './InterpretationMessageDialog';
import { RootState } from '../../store';
import { Action, Dispatch } from 'redux';
import { requestActionBundles } from '../../store/data/action';
import { pipe } from 'ramda';
import {
  selectInfection,
  selectIsInterpretationMessageDialogOpen,
  selectMessage,
  selectObservationIdToBeInterpreted,
} from '../../store/observation-interpret/selector';
import { closeInterpretationMessageDialog, setMessage } from '../../store/observation-interpret/actions';
import { selectIsInterpretingObservation } from '../../store/data/selector';

const mapStateToProps = (state: RootState) => ({
  observationId: selectObservationIdToBeInterpreted(state),
  infection: selectInfection(state),
  message: selectMessage(state),
  isOpen: selectIsInterpretationMessageDialogOpen(state),
  isInterpretingObservation: (id: string) => selectIsInterpretingObservation(id)(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  setMessage: pipe(setMessage, dispatch),
  interpretObservation: pipe(requestActionBundles.interpretObservation.sendRequest, dispatch),
  closeDialog: pipe(closeInterpretationMessageDialog, dispatch),
});

type OwnProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
export type Props = Readonly<OwnProps & StateProps & DispatchProps>;

export default connect(mapStateToProps, mapDispatchToProps)(InterpretationMessageDialog);
