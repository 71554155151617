// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from 'react';
import { classNames } from '@plasmicapp/react-web';

export type InfoIconProps = React.ComponentProps<'svg'> & {
  title?: string;
};

export function InfoIcon(props: InfoIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'none'}
      viewBox={'0 0 16 16'}
      height={'1em'}
      width={'1em'}
      style={{
        fill: 'currentcolor',

        ...(style || {}),
      }}
      className={classNames('plasmic-default__svg', className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={'M9 7c0-.6-.4-1-1-1H5v2h2v6H5v2h6v-2H9V7zM7.5 4a1.5 1.5 0 100-3 1.5 1.5 0 000 3z'}
        fill={'currentColor'}
      ></path>
    </svg>
  );
}

export default InfoIcon;
/* prettier-ignore-end */
