import { removeRequestSuffixFromActionType } from 'common/request-action';
import { any, eqBy, find, isEmpty, map, pipe, sortBy, values } from 'ramda';
import { requestActionBundles } from 'store/data/action';
import { RootState } from 'store/index';
import { createSelector } from 'reselect';
import { ObservationDTO, OrderState, OrderDetailsDTO } from '../../types/data';
import { Infection, Interpretation } from '../../types/common';

const selectDataState = ({ data }: RootState) => data;

const selectPatientReport = pipe(selectDataState, ({ patientReport }) => patientReport);

const selectPagedOrders = pipe(selectDataState, ({ pagedOrders }) => pagedOrders);

export const selectInternalUsers = pipe(selectDataState, ({ users }) => users);

const sortObservations = (observations: readonly ObservationDTO[]) =>
  sortBy((observation: ObservationDTO) => values(Infection).indexOf(observation.infection), observations);

const calculateOrderStates: (order: OrderDetailsDTO) => OrderState[] = ({
  patientHasAccessed,
  isDocumentedInPIS,
  isBilled,
  observations,
}) => {
  const needsManualInterpretation = any(
    (result) => result.interpretation === Interpretation.NEEDS_MANUAL_INTERPRETATION,
    observations
  );
  const isWaitingForResults = any(
    (result) => result.interpretation === Interpretation.WAITING_FOR_RESULTS,
    observations
  );
  const allInfoForPatientIsPresent = !(needsManualInterpretation || isWaitingForResults);
  const states = (needsManualInterpretation ? [OrderState.NEEDS_MANUAL_INTERPRETATION] : [])
    .concat(isWaitingForResults ? [OrderState.NOT_ALL_RESULTS_AVAILABLE] : [])
    .concat(allInfoForPatientIsPresent && !patientHasAccessed ? [OrderState.PATIENT_HAS_NOT_ACCESSED] : [])
    .concat(allInfoForPatientIsPresent && !isDocumentedInPIS ? [OrderState.IS_NOT_DOCUMENTED_IN_PIS] : [])
    .concat(allInfoForPatientIsPresent && !isBilled ? [OrderState.IS_NOT_BILLED] : []);
  return isEmpty(states) ? [OrderState.COMPLETED] : states;
};

export const selectPagedOrdersWithStates = createSelector(selectPagedOrders, (pagedOrders) =>
  pagedOrders
    ? map(
        (order) => ({
          ...order,
          observations: sortObservations(order.observations),
          states: calculateOrderStates(order),
        }),
        pagedOrders
      )
    : undefined
);

export const selectOrder = createSelector(
  selectPagedOrders,
  (pagedOrders) => (orderId: string) =>
    find((order) => order.id === orderId, pagedOrders ?? []) as OrderDetailsDTO | undefined
);

export const selectOrderedPatientReport = createSelector(selectPatientReport, (patientReport) =>
  patientReport
    ? {
        ...patientReport,
        results: map(
          ({ interpretation, infection, ...rest }: ObservationDTO) => ({
            interpretation:
              infection === Infection.HIV && interpretation === Interpretation.POSITIVE
                ? Interpretation.VALUES_INCREASED
                : interpretation,
            infection,
            ...rest,
          }),
          sortObservations(patientReport.results)
        ),
      }
    : undefined
);

export const selectItemCount = pipe(selectDataState, ({ orderItemCount }) => orderItemCount ?? 0);

export const selectIsRequestRunning = pipe(selectDataState, ({ requestsRunning }) => requestsRunning.length > 0);

const makeGetIsRequestRunning = (type: string) =>
  pipe(selectDataState, ({ requestsRunning }) => any(eqBy(removeRequestSuffixFromActionType, type), requestsRunning));

export const selectIsInterpretingObservation = (id: string) =>
  makeGetIsRequestRunning(
    requestActionBundles.interpretObservation.sendRequest({
      id,
      interpretation: Interpretation.WAITING_FOR_RESULTS,
      message: null,
    }).type
  );

export const selectIsUpdatingOrderIsBilled = makeGetIsRequestRunning(
  requestActionBundles.updateOrderIsBilled.sendRequest({
    orderId: "doesn't matter",
    isBilled: false /*doesn't matter*/,
  }).type
);

export const selectIsUpdatingOrderIsDocumentedInPIS = makeGetIsRequestRunning(
  requestActionBundles.updateOrderIsDocumentedInPIS.sendRequest({
    orderId: "doesn't matter",
    isDocumentedInPIS: false /*doesn't matter*/,
  }).type
);

export const selectIsLoadingPagedOrders = makeGetIsRequestRunning(
  requestActionBundles.readPagedOrders.sendRequest().type
);

export const selectIsCreatingOrder = makeGetIsRequestRunning(requestActionBundles.createOrder.sendRequest().type);

export const selectIsResendingSMS = (reportId: string) =>
  makeGetIsRequestRunning(requestActionBundles.resendSMS.sendRequest(reportId).type);

export const selectIsUpdatingOrderWithFormData = (reportId: string) =>
  makeGetIsRequestRunning(requestActionBundles.updateOrderWithFormData.sendRequest(reportId).type);

export const selectIsDeletingOrder = (reportId: string) =>
  makeGetIsRequestRunning(requestActionBundles.deleteOrder.sendRequest(reportId).type);

export const selectIsLoadingInternalUsers = makeGetIsRequestRunning(
  requestActionBundles.readAllInternalUsers.sendRequest().type
);
