import * as React from 'react';
import { useEffect, useRef } from 'react';
import {
  DefaultPassCodeLetterProps,
  PlasmicPassCodeLetter,
} from '../../plasmic/checkpoint_zurich/PlasmicPassCodeLetter';
import { HTMLElementRefOf } from '@plasmicapp/react-web';

type Props = DefaultPassCodeLetterProps &
  Readonly<{
    value: string | undefined;
    isFocused: boolean;
    disabled?: boolean;
    onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    onFocus: (e: React.FocusEvent<HTMLInputElement>) => void;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  }>;

const PassCodeLetterView = (
  { value, isFocused, disabled, onKeyDown, onChange, ...rest }: Props,
  ref: HTMLElementRefOf<'div'>
) => {
  const textboxRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (isFocused) {
      const input = textboxRef.current;
      if (input != null) {
        input.focus();
        input.select();
      }
    }
  });

  return (
    <PlasmicPassCodeLetter
      root={{ ref }}
      {...rest}
      textbox={{
        ref: textboxRef,
        value: value ?? '',
        disabled,
        onKeyDown,
        onChange,
      }}
    />
  );
};

export default React.forwardRef(PassCodeLetterView);
