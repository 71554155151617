import { RootState } from '../../store';
import { Action, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { selectItemCount, selectPagedOrdersWithStates, selectIsLoadingPagedOrders } from '../../store/data/selector';
import { pipe } from 'ramda';
import { selectCurrentPage, selectSortBy, selectSortDirection } from '../../store/order-overview/selector';
import { setCurrentPage, setSort } from '../../store/order-overview/action';
import OrderTable from './OrderTable';

const mapStateToProps = (state: RootState) => ({
  orders: selectPagedOrdersWithStates(state),
  isLoading: selectIsLoadingPagedOrders(state),
  itemCount: selectItemCount(state),
  currentPage: selectCurrentPage(state),
  sortBy: selectSortBy(state),
  orderDirection: selectSortDirection(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  setPage: pipe(setCurrentPage, dispatch),
  setSort: pipe(setSort, dispatch),
});

type OwnProps = {};
type StateProps = Readonly<ReturnType<typeof mapStateToProps>>;
type DispatchProps = Readonly<ReturnType<typeof mapDispatchToProps>>;
export type Props = OwnProps & StateProps & DispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(OrderTable);
