import React from 'react';
import './index.css';
import App from './components/root/App';
import { keycloak } from './common/keycloak';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import * as Sentry from '@sentry/react';
import * as SentryTracing from '@sentry/tracing';
import * as SentryIntegrations from '@sentry/integrations';
import { environment } from './common/utility/environment';
import { rootReducer, rootSaga } from './store';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';

Sentry.init({
  dsn: environment === 'localhost' ? '' : process.env.REACT_APP_SENTRY_DSN,
  autoSessionTracking: false,
  integrations: [new SentryTracing.Integrations.BrowserTracing(), new SentryIntegrations.Offline()],
  tracesSampleRate: 0.1,
  environment,
  release: process.env.REACT_APP_FRONTEND_VERSION,
});

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(rootSaga);

if (window.location.pathname.startsWith('/admin')) {
  keycloak
    .init({ onLoad: 'login-required' })
    .then((authenticated) => {
      if (!authenticated) throw Error('authentication failed');
      render();
    })
    .catch((e: Error) => {
      console.error(e);
      alert(e?.message);
    });
} else {
  render();
}

function render() {
  const container = document.getElementById('root');
  if (container) {
    const root = createRoot(container);
    root.render(
      <React.StrictMode>
        <Provider store={store}>
          <App />
        </Provider>
      </React.StrictMode>
    );
  }
}
