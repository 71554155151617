import * as React from 'react';
import PlasmicPassCodeForm from '../../plasmic/checkpoint_zurich/PlasmicPassCodeForm';
import PassCodeLetter from './PassCodeLetter';
import { Props } from './PassCodeForm';
import { HTMLElementRefOf } from '@plasmicapp/react-web';
import { isEmpty } from 'ramda';
import { useAccessToken } from '../../../hooks/useAccessToken';

const PassCodeFormView = (
  {
    formState,
    currentPosition,
    disabled,
    isValid,
    setPosition,
    setRelativePosition,
    setPasscodeLetter,
    clearPasscodeLetter,
    loginToPatientReport,
    ...rest
  }: Props,
  ref: HTMLElementRefOf<'div'>
) => {
  const accessToken = useAccessToken();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value: readonly string[] = e.target.value.split('');
    if (isEmpty(value)) {
      clearPasscodeLetter();
    } else {
      value.forEach(setPasscodeLetter);
    }
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' || e.key === 'Delete') {
      e.preventDefault();
      clearPasscodeLetter();
    } else if (e.key === 'ArrowRight') {
      setRelativePosition(1);
    } else if (e.key === 'ArrowLeft') {
      setRelativePosition(-1);
    } else if (e.key === 'Enter') {
      if (isValid && accessToken) {
        loginToPatientReport(accessToken);
      }
    }
  };

  return (
    <>
      <PlasmicPassCodeForm root={{ ref }} {...rest}>
        {formState.passcodeLetters.value.map((value: string, index: number) => (
          <PassCodeLetter
            key={index}
            {...rest}
            isFocused={currentPosition === index}
            value={value}
            disabled={disabled}
            onKeyDown={onKeyDown}
            onChange={onChange}
            onFocus={() => setPosition(index)}
          />
        ))}
      </PlasmicPassCodeForm>
    </>
  );
};

export default PassCodeFormView;
