import { createReducer } from 'deox';
import { closeInterpretationMessageDialog, openInterpretationMessageDialog, setMessage } from './actions';
import { Infection } from '../../types/common';
import produce from 'immer';

const initialState: Readonly<{
  dialogIsOpen: boolean;
  observationId?: string;
  infection?: Infection;
  message: string;
}> = {
  dialogIsOpen: false,
  message: '',
};

export const observationInterpretReducer = createReducer(initialState, (handleAction) => [
  handleAction(openInterpretationMessageDialog, (state, { payload: { id, infection } }) =>
    produce(state, (draft) => {
      draft.dialogIsOpen = true;
      draft.observationId = id;
      draft.infection = infection;
    })
  ),
  handleAction(closeInterpretationMessageDialog, (state) =>
    produce(state, (draft) => {
      draft.dialogIsOpen = false;
      draft.observationId = undefined;
      draft.infection = undefined;
    })
  ),
  handleAction(setMessage, (state, { payload: { message } }) =>
    produce(state, (draft) => {
      draft.message = message;
    })
  ),
]);
