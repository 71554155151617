import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { OVERVIEW_PAGE_SIZE } from '../../constants';
import { isEmpty, isNil, map } from 'ramda';
import { CircularProgress, makeStyles, TableBody, TableSortLabel, Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import OrderRow from './OrderRow';
import { Props } from './OrderTableContainer';

const useStyles = makeStyles((theme: Theme) => ({
  tableContainer: {
    maxHeight: 'calc(100vh - 250px)',
    overflowY: 'scroll',
  },
  headerCell: {
    backgroundColor: 'white',
  },
}));

export const columns = ['created', 'firstName', 'lastName', 'dateOfBirth', 'phone', 'kgNr'] as const;

export default function OrderTable({
  orders,
  itemCount,
  currentPage,
  sortBy,
  orderDirection,
  setPage,
  setSort,
}: Props) {
  const classes = useStyles();
  const [t] = useTranslation();

  const defineNewDirection = (columnId: string) => {
    if (columnId === sortBy) {
      return orderDirection === 'asc' ? 'desc' : 'asc';
    }
    return 'asc';
  };

  return (
    <>
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader>
          <colgroup>
            <col width={'10%'} />
            <col width={'12%'} />
            <col width={'20%'} />
            <col width={'20%'} />
            <col width={'12%'} />
            <col width={'12%'} />
            <col width={'10%'} />
            <col width={'4%'} />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell className={classes.headerCell} />
              {map(
                (column) => (
                  <TableCell key={column} className={classes.headerCell} align={'left'}>
                    <TableSortLabel
                      active={sortBy === column}
                      direction={sortBy === column ? orderDirection : 'asc'}
                      onClick={() =>
                        setSort({
                          columnId: column,
                          direction: defineNewDirection(column),
                        })
                      }
                    >
                      {t(`orderOverview.tableEntry.${column}`)}
                    </TableSortLabel>
                  </TableCell>
                ),
                columns
              )}
              <TableCell className={classes.headerCell} />
            </TableRow>
          </TableHead>
          <TableBody>
            {(isNil(orders) || isEmpty(orders)) && (
              <TableRow>
                <TableCell colSpan={columns.length + 2} align={'center'}>
                  {isNil(orders) ? (
                    <CircularProgress color={'secondary'} />
                  ) : (
                    t(`orderOverview.tableEntry.placeholderIfEmpty`)
                  )}
                </TableCell>
              </TableRow>
            )}
            {map(
              (order) => (
                <OrderRow key={order.id} {...order} />
              ),
              orders ?? []
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={itemCount}
        onPageChange={(e, page) => setPage(page)}
        page={currentPage}
        rowsPerPage={OVERVIEW_PAGE_SIZE}
        rowsPerPageOptions={[]}
      />
    </>
  );
}
