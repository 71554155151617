import * as React from 'react';
import { DefaultDashboardInfoProps, PlasmicDashboardInfo } from '../plasmic/checkpoint_zurich/PlasmicDashboardInfo';
import { HTMLElementRefOf } from '@plasmicapp/react-web';
import { useTranslation } from 'react-i18next';

function DashboardInfo(props: DefaultDashboardInfoProps, ref: HTMLElementRefOf<'div'>) {
  const [t] = useTranslation();

  return (
    <PlasmicDashboardInfo
      title={t('patientResult.colorMeaning.title')}
      okText={t('patientResult.colorMeaning.okText')}
      callText={t('patientResult.colorMeaning.callText')}
      appointmentText={t('patientResult.colorMeaning.appointmentText')}
      analysisPendingText={t('patientResult.colorMeaning.analysisPendingText')}
      root={{ ref }}
      {...props}
    />
  );
}

export default React.forwardRef(DashboardInfo);
