import { call, put, select, takeLatest } from 'redux-saga/effects';
import { requestActionBundles, setPatientReport } from './action';
import { ActionType, getType } from 'deox';
import { getIsLoginPatientFormValid, getLoginPatientFormValues } from '../patient-login/selector';
import { v4 as uuid } from 'uuid';
import { addAlert } from '../alert/action';
import { AlertSeverity } from '../../types/alert';
import { endpoints } from '../../common/api/api';
import { AxiosResponse } from 'axios';
import { getApiClientForMethod } from '../../common/api/request-builder';
import {
  logWarningIfValidationRemarksArePresent,
  transformWithSchema,
} from '@healthinal/typescript-schema-transformer';
import { PatientAccessDTO } from '../../types/data';
import { routerHistory, RoutePaths } from '../../components/router/Router';
import { loadPatientCredentials, storePatientCredentials } from '../../common/patient-credentials';
import { patientReportDTOSchema } from '../../types/schemas';

function* readPatientReportSaga({ accessToken, passcode }: PatientAccessDTO) {
  const { method, url } = endpoints.patientReport.read();
  const result: AxiosResponse = yield call(getApiClientForMethod(method), url, {
    accessToken,
    passcode,
  } as PatientAccessDTO);
  const [patientReport, validationRemarks] = transformWithSchema(patientReportDTOSchema, result.data);
  logWarningIfValidationRemarksArePresent('read patient report saga', validationRemarks);
  yield put(setPatientReport(patientReport));
}

function* reloadPatientReportSaga() {
  try {
    const patientCredentials = loadPatientCredentials();
    if (!patientCredentials) {
      yield put(
        requestActionBundles.refetchPatientReport.requestFailed({
          id: yield call(uuid),
          message: 'Anfrage kann nicht ausgeführt werden, da der User nicht eingeloggt ist.',
        })
      );
      yield call(routerHistory.push, RoutePaths.PATIENT_LOGIN);
      return;
    }
    yield call(readPatientReportSaga, patientCredentials);
    yield put(requestActionBundles.refetchPatientReport.requestSucceeded());
  } catch (error) {
    console.log(error);
    yield put(
      requestActionBundles.refetchPatientReport.requestFailed({
        id: yield call(uuid),
        message: JSON.stringify(error),
      })
    );
  }
}

function* loginSaga(action: ActionType<typeof requestActionBundles.loginToPatientReport.sendRequest>) {
  try {
    const isValid: boolean = yield select(getIsLoginPatientFormValid);
    const accessToken = action.payload.accessToken;
    if (!isValid) {
      yield put(
        requestActionBundles.loginToPatientReport.requestFailed({
          id: yield call(uuid),
          message: 'Request cannot be completed. Not all data is present in the form.',
        })
      );
      return;
    }
    const form: { passcodeLetters: readonly string[] } = yield select(getLoginPatientFormValues);
    const passcode = form.passcodeLetters.join('');
    yield call(storePatientCredentials, { accessToken, passcode });
    yield call(readPatientReportSaga, { accessToken, passcode });
    yield put(requestActionBundles.loginToPatientReport.requestSucceeded());
    yield call(routerHistory.push, RoutePaths.PATIENT_RESULTS);
  } catch (error) {
    console.log(error);
    const id: string = yield call(uuid);
    yield put(addAlert(id, 'patientLogin.error', AlertSeverity.ERROR));
    yield put(
      requestActionBundles.loginToPatientReport.requestFailed({
        id,
        message: JSON.stringify(error),
      })
    );
  }
}

export function* patientReportSaga() {
  yield takeLatest(getType(requestActionBundles.loginToPatientReport.sendRequest), loginSaga);
  yield takeLatest(getType(requestActionBundles.refetchPatientReport.sendRequest), reloadPatientReportSaga);
}
