import { FormControlLabel, Switch } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export default function Filter({
  checked,
  onChange,
  translationKey,
}: {
  checked: boolean;
  onChange: () => unknown;
  translationKey: string;
}) {
  const [t] = useTranslation();

  return (
    <FormControlLabel
      control={<Switch color="primary" size="small" checked={checked} onChange={onChange} />}
      label={t(`orderOverview.filter.${translationKey}`)}
    />
  );
}
