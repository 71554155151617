import { RootState } from '../../store';
import { Action, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { OrderDeleteDialog } from './OrderDeleteDialog';
import { closeDeleteDialog } from '../../store/order-delete/action';
import { pipe } from 'ramda';
import { requestActionBundles } from '../../store/data/action';
import { selectIsDeleteDialogOpen, selectReportToBeDeleted } from '../../store/order-delete/selector';
import { selectIsDeletingOrder } from '../../store/data/selector';

const mapStateToProps = (state: RootState) => ({
  id: selectReportToBeDeleted(state),
  isDeletingOrder: selectIsDeletingOrder(selectReportToBeDeleted(state) ?? '')(state),
  isOpen: selectIsDeleteDialogOpen(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  deleteOrder: pipe(requestActionBundles.deleteOrder.sendRequest, dispatch),
  closeDeleteDialog: pipe(closeDeleteDialog, dispatch),
});

type OwnProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
export type Props = Readonly<OwnProps & StateProps & DispatchProps>;

export default connect(mapStateToProps, mapDispatchToProps)(OrderDeleteDialog);
