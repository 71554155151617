import { FormControl, FormGroup, FormLabel, makeStyles } from '@material-ui/core';
import { Infection } from '../../types/common';
import InfectionCheckboxControl from './InfectionCheckboxControl';
import { FormFieldSelectorResult } from '../../types/form';
import ErrorMessage from './ErrorMessage';
import { useTranslation } from 'react-i18next';
import { includes, map, take, takeLast, values } from 'ramda';

const useStyles = makeStyles({
  formControl: {
    width: '100%',
  },
  formGroupContent: {
    display: 'flex',
  },
  checkBoxColumn: {
    width: '50%',
  },
});

type Props = {
  formField: FormFieldSelectorResult<{ [infection in Infection]: boolean }>;
  setValues?: (payload: { infections: readonly Infection[]; present: boolean }) => unknown;
  disabled?: boolean;
  infectionsAlreadyProcessed?: Infection[];
};

const InfectionCheckboxGroup = ({ formField, setValues, disabled, infectionsAlreadyProcessed }: Props) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const checkboxes = map((infection) => {
    const isAlreadyProcessed = includes(infection, infectionsAlreadyProcessed ?? []);
    return (
      <InfectionCheckboxControl
        key={infection}
        elements={[infection]}
        label={t(infection) + (isAlreadyProcessed ? ` ${t('orderUpdate.alreadyProcessed')}` : '')}
        fieldValues={formField.value}
        setValues={setValues}
        disabled={disabled || isAlreadyProcessed}
      />
    );
  }, values(Infection));
  const halfLength = Math.ceil(checkboxes.length / 2);

  return (
    <FormControl className={classes.formControl}>
      <FormLabel>{t('orderForm.infectionCheckboxGroupLabel')}</FormLabel>
      <FormGroup>
        <div className={classes.formGroupContent}>
          <div className={classes.checkBoxColumn}>{take(halfLength, checkboxes)}</div>
          <div className={classes.checkBoxColumn}>{takeLast(checkboxes.length - halfLength, checkboxes)}</div>
        </div>
      </FormGroup>
      <ErrorMessage formField={formField} />
    </FormControl>
  );
};

export default InfectionCheckboxGroup;
