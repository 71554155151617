import TextField from './TextField';
import { useTranslation } from 'react-i18next';
import { FormFieldSelectorResult } from '../../types/form';

type Props = Readonly<{
  kgNrField: FormFieldSelectorResult;
  setKgNr?: (value: string) => unknown;
  disabled?: boolean;
}>;

export default function KgNrField({ kgNrField, setKgNr, disabled }: Props) {
  const [t] = useTranslation();

  return <TextField label={t('orderForm.kgNr')} formField={kgNrField} setValue={setKgNr} disabled={disabled} />;
}
