import { eventChannel } from 'redux-saga';
import { put, select, take } from 'redux-saga/effects';
import { keycloak } from '../../common/keycloak';
import { requestActionBundles } from '../data/action';
import { logoutAdmin, setRoles } from './action';
import { selectIsInternalEmployee } from './selector';

const authenticationChannel = eventChannel((emit) => {
  keycloak.onReady = (authenticated) => emit(!!authenticated);

  return () => {
    keycloak.onReady = () => {};
  };
});

export function* adminLoginSaga(): Generator<any, any, any> {
  while (true) {
    const isAuthenticated = yield take(authenticationChannel);
    if (isAuthenticated) {
      const roles = keycloak.tokenParsed?.realm_access?.roles;
      yield put(setRoles(Array.isArray(roles) ? roles : []));
    } else {
      yield put(logoutAdmin());
    }
    if (yield select(selectIsInternalEmployee)) {
      yield put(requestActionBundles.readPagedOrders.sendRequest());
    }
  }
}
