import { FormField } from '../../types/form';
import { isEmpty, repeat } from 'ramda';
import { createFormFieldHandlers } from '../../common/form-validation/reducer';
import { requiredPasscodeValidator } from '../../common/form-validation/validators';
import { createReducer } from 'deox';
import { clearPasscodeLetter, setCurrentPosition, setPasscodeLetter, setRelativePosition } from './action';
import produce from 'immer';
import { PASSCODE_LENGTH } from '../../constants';
import { setCreatedOrder } from '../data/action';

const { init: requiredPasscodeFormField, validate: validatePasscodeFormField } = createFormFieldHandlers<Array<string>>(
  repeat('', PASSCODE_LENGTH),
  [requiredPasscodeValidator]
);

export const initialState: Readonly<{
  currentPosition: number;
  form: Readonly<{
    passcodeLetters: FormField<string[]>;
  }>;
}> = {
  currentPosition: 0,
  form: {
    passcodeLetters: requiredPasscodeFormField,
  },
};

const mapAndValidatePasscodeLetters = (
  passcodeLetter: string,
  positionToBeUpdated: number,
  passCodeLetters: FormField<string[]>
) =>
  validatePasscodeFormField(
    passCodeLetters.value.map((element, index) =>
      index === positionToBeUpdated ? passcodeLetter.toUpperCase() : element
    )
  );

export const patientLoginReducer = createReducer(initialState, (handleAction) => [
  handleAction(setPasscodeLetter, ({ currentPosition, form }, { payload: { passcodeLetter } }) =>
    produce({ currentPosition, form }, (draft) => {
      draft.form.passcodeLetters = mapAndValidatePasscodeLetters(
        passcodeLetter,
        currentPosition,
        draft.form.passcodeLetters
      );
      const nextPosition = currentPosition + 1;
      if (nextPosition < PASSCODE_LENGTH) {
        draft.currentPosition = nextPosition;
      }
    })
  ),
  handleAction(
    setCreatedOrder,
    (
      { currentPosition, form },
      {
        payload: {
          order: { passcode },
        },
      }
    ) =>
      produce({ currentPosition, form }, (draft) => {
        draft.form.passcodeLetters = validatePasscodeFormField(passcode.split(''));
      })
  ),
  handleAction(setCurrentPosition, (state, { payload: { position } }) =>
    produce(state, (draft) => {
      draft.currentPosition = position;
    })
  ),
  handleAction(setRelativePosition, (state, { payload: { offset } }) =>
    produce(state, (draft) => {
      const newPosition = state.currentPosition + offset;
      if (newPosition >= 0 && newPosition < PASSCODE_LENGTH) {
        draft.currentPosition = newPosition;
      }
    })
  ),
  handleAction(clearPasscodeLetter, ({ currentPosition, form }) =>
    produce({ currentPosition, form }, (draft) => {
      const currentLetterIsEmpty = isEmpty(form.passcodeLetters.value[currentPosition]);
      draft.form.passcodeLetters = mapAndValidatePasscodeLetters(
        '',
        currentLetterIsEmpty ? currentPosition - 1 : currentPosition,
        draft.form.passcodeLetters
      );
      draft.currentPosition = currentPosition > 0 && currentLetterIsEmpty ? currentPosition - 1 : currentPosition;
    })
  ),
]);
