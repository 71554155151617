import { connect } from 'react-redux';
import { Dispatch, Action } from 'redux';
import { RootState } from '../../store';
import { getAlerts } from '../../store/alert/selector';
import { removeAlert } from '../../store/alert/action';
import AppAlerts from './AppAlerts';
import { pipe } from 'ramda';

const mapStateToProps = (state: RootState) => ({
  alerts: getAlerts(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  removeAlert: pipe(removeAlert, dispatch),
});

type StateProps = Readonly<ReturnType<typeof mapStateToProps>>;
type DispatchProps = Readonly<ReturnType<typeof mapDispatchToProps>>;
export type Props = StateProps & DispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(AppAlerts);
