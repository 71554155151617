import { RootState } from '../index';
import { map, pipe, values } from 'ramda';
import {
  selectFormFieldValue,
  selectFormFieldViewValues,
  selectIsFormValid,
} from '../../common/form-validation/selector';
import { OrderFormValues, FormState } from '../../types/form';

const selectOrderCreateState = ({ orderCreate }: RootState) => orderCreate;

const selectForm = pipe(selectOrderCreateState, ({ form }) => form);

export const selectOrderFormState = pipe(
  selectForm,
  map(selectFormFieldViewValues) as any as (
    form: ReturnType<typeof selectForm>
  ) => FormState<ReturnType<typeof selectForm>>
);

const mapToFormFieldValues: (form: any) => OrderFormValues = map(selectFormFieldValue) as any;

export const selectOrderCreateFormValues = pipe(selectForm, mapToFormFieldValues);

export const selectIsOrderCreateFormValid: (rootState: RootState) => boolean = pipe(
  selectForm,
  values,
  selectIsFormValid
);
