import { createTheme, MuiThemeProvider, Typography } from '@material-ui/core';
import '../../translations/setup';
import RouterContainer from '../router/RouterContainer';

const theme = createTheme({
  palette: {
    primary: {
      main: '#000000',
      light: 'rgba(0,0,0,0.54)',
      contrastText: 'white',
    },
    secondary: {
      main: '#1A9EBD',
    },
    error: {
      light: 'rgb(243, 88, 80, 0.54)',
      main: '#F35850',
      contrastText: '#000000b3',
    },
    success: {
      main: '#AFFAB6',
      contrastText: '#000000b3',
    },
  },
});

const App = () => {
  return (
    <MuiThemeProvider theme={theme}>
      <Typography component="div">
        <RouterContainer />
      </Typography>
    </MuiThemeProvider>
  );
};

export default App;
