import * as React from 'react';
import { DefaultMainNavProps, PlasmicMainNav } from '../plasmic/checkpoint_zurich/PlasmicMainNav';
import { HTMLElementRefOf } from '@plasmicapp/react-web';

type Props = DefaultMainNavProps &
  Readonly<{
    onLogout: () => void;
  }>;

const MainNav = ({ onLogout, ...rest }: Props, ref: HTMLElementRefOf<'div'>) => (
  <PlasmicMainNav
    root={{ ref }}
    logoutButton={{
      onClick: onLogout,
    }}
    {...rest}
  />
);

export default React.forwardRef(MainNav);
