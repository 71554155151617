import { createActionCreator } from 'deox';
import { Infection } from '../../types/common';

export const openInterpretationMessageDialog = createActionCreator(
  'ObservationInterpret/OPEN_DIALOG',
  (resolve) => (payload: { id: string; infection: Infection }) => resolve(payload)
);

export const closeInterpretationMessageDialog = createActionCreator('ObservationInterpret/CLOSE_DIALOG');

export const setMessage = createActionCreator(
  'ObservationInterpret/SET_MESSAGE',
  (resolve) => (payload: { message: string }) => resolve(payload)
);
