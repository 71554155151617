import { RootState } from '../../store';
import { Action, Dispatch } from 'redux';
import { requestActionBundles } from '../../store/data/action';
import { connect } from 'react-redux';
import { pipe } from 'ramda';
import { OrderDetailsDTOWithStates } from '../../types/data';
import { openDeleteDialog } from '../../store/order-delete/action';
import {
  selectIsResendingSMS,
  selectIsUpdatingOrderIsBilled,
  selectIsUpdatingOrderIsDocumentedInPIS,
} from '../../store/data/selector';
import { openInterpretationMessageDialog } from '../../store/observation-interpret/actions';
import OrderDetails from './OrderDetails';

const mapStateToProps = (state: RootState) => ({
  isResendingSMS: (orderId: string) => selectIsResendingSMS(orderId)(state),
  isUpdatingIsDocumentedInPIS: () => selectIsUpdatingOrderIsDocumentedInPIS(state),
  isUpdatingIsBilled: () => selectIsUpdatingOrderIsBilled(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  setIsDocumentedInPIS: pipe(requestActionBundles.updateOrderIsDocumentedInPIS.sendRequest, dispatch),
  setIsBilled: pipe(requestActionBundles.updateOrderIsBilled.sendRequest, dispatch),
  updateOrder: pipe(requestActionBundles.updateOrderWithFormData.sendRequest, dispatch),
  interpretObservation: pipe(requestActionBundles.interpretObservation.sendRequest, dispatch),
  resendSMS: pipe(requestActionBundles.resendSMS.sendRequest, dispatch),
  openDeleteDialog: pipe(openDeleteDialog, dispatch),
  openInterpretationMessageDialog: pipe(openInterpretationMessageDialog, dispatch),
});

type OwnProps = { order: OrderDetailsDTOWithStates };
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
export type Props = Readonly<OwnProps & StateProps & DispatchProps>;

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);
