import { createReducer } from 'deox';
import { logoutAdmin, setRoles } from './action';
import produce from 'immer';

export const initialState: Readonly<{
  roles?: readonly string[];
}> = {};

export const adminLoginReducer = createReducer(initialState, (handleAction) => [
  handleAction(setRoles, (state, { payload: { roles } }) =>
    produce(state, (draft) => {
      draft.roles = roles;
    })
  ),
  handleAction(logoutAdmin, (state) =>
    produce(state, (draft) => {
      draft.roles = undefined;
    })
  ),
]);
