import MainNav from '../app-bar/MainNav';
import { keycloak } from '../../common/keycloak';
import { useNavigate } from 'react-router-dom';
import { Button, FormGroup, makeStyles, Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PrimaryButton from '../buttons/PrimaryButton';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import SearchBarContainer from './SearchBarContainer';
import React from 'react';
import { FilterOff } from 'mdi-material-ui';
import Filter from './Filter';
import OrderTableContainer from './OrderTableContainer';
import { Props } from './OrderOverviewContainer';
import { RoutePaths } from '../router/Router';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    width: '100%',
    overflow: 'hidden',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0),
    margin: theme.spacing(0, 2, 0.125, 1),
  },
  bold: {
    fontWeight: 'bold',
  },
  list: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(0.125),
    overflowY: 'auto',
    height: 'calc(100vh - 200px)',
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  filterButton: {
    width: '250px',
  },
  filterGroup: {
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'row',
    columnGap: theme.spacing(1),
    paddingTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
}));

export default function OrderOverview({
  filterByUser,
  setFilterByUser,
  filterByInterpretationNeeded,
  setFilterByInterpretationNeeded,
  filterByPositiveInterpretation,
  setFilterByPositiveInterpretation,
  deleteAllFilters,
}: Props) {
  const navigate = useNavigate();
  const classes = useStyles();
  const [t] = useTranslation();

  return (
    <>
      <MainNav loggedIn={true} doctorView={true} onLogout={keycloak.logout} />
      <Container maxWidth="lg" className={classes.container}>
        <Paper className={classes.paper}>
          <div className={classes.header}>
            <Button
              variant="outlined"
              startIcon={<FilterOff />}
              disabled={!(filterByUser || filterByInterpretationNeeded || filterByPositiveInterpretation)}
              onClick={() => deleteAllFilters()}
              className={classes.filterButton}
            >
              {t('orderOverview.filter.delete')}
            </Button>
            <SearchBarContainer />
            <PrimaryButton
              onClick={() => navigate(RoutePaths.CREATE_ORDER)}
              translationKey="orderCreate.createButton"
            />
          </div>
          <FormGroup className={classes.filterGroup}>
            <Filter checked={filterByUser} onChange={() => setFilterByUser(!filterByUser)} translationKey={'byUser'} />
            <Filter
              checked={filterByInterpretationNeeded}
              onChange={() => setFilterByInterpretationNeeded(!filterByInterpretationNeeded)}
              translationKey={'byInterpretationNeeded'}
            />
            <Filter
              checked={filterByPositiveInterpretation}
              onChange={() => setFilterByPositiveInterpretation(!filterByPositiveInterpretation)}
              translationKey={'byPositiveInterpretation'}
            />
          </FormGroup>
          <OrderTableContainer />
        </Paper>
      </Container>
    </>
  );
}
