import { RootState } from '../../store';
import { getIsLoginPatientFormValid } from '../../store/patient-login/selector';
import { Action, Dispatch } from 'redux';
import { DefaultLoginProps } from '../plasmic/checkpoint_zurich/PlasmicLogin';
import { connect } from 'react-redux';
import React from 'react';
import LoginView from './LoginView';
import { requestActionBundles } from '../../store/data/action';
import { pipe } from 'ramda';

const mapStateToProps = (state: RootState) => ({
  isValid: getIsLoginPatientFormValid(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  loginToPatientReport: pipe(requestActionBundles.loginToPatientReport.sendRequest, dispatch),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
export type Props = Readonly<DefaultLoginProps & StateProps & DispatchProps>;

export default connect(mapStateToProps, mapDispatchToProps)(React.forwardRef(LoginView));
