import { Collapse, IconButton, makeStyles, TableCellProps, TableRow, Theme } from '@material-ui/core';
import { OrderState, OrderDetailsDTOWithStates } from '../../types/data';
import { useState } from 'react';
import {
  AccountEye,
  CheckboxMarkedCircleOutline,
  ChevronDown,
  ChevronUp,
  Clock,
  CurrencyUsd,
  Eye,
  SwapHorizontal,
} from 'mdi-material-ui';
import { includes, map, props } from 'ramda';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';
import OrderDetailsContainer from './OrderDetailsContainer';
import { columns } from './OrderTable';

const useStyles = makeStyles((theme: Theme) => ({
  tableRow: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  stateIcons: {
    display: 'flex',
    columnGap: theme.spacing(0.5),
  },
  stateIcon: {
    display: 'flex',
    fontSize: 'inherit',
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  toBeViewedIcon: {
    color: theme.palette.error.main,
  },
  doneIcon: {
    color: '#208E1D',
  },
  openRow: {
    background: '#f6f6f6',
  },
  cell: {
    padding: theme.spacing(0, 2, 0, 2),
  },
}));

export default function OrderRow({
  id,
  states,
  observations,
  passcode,
  billingInfo,
  ...columnValues
}: OrderDetailsDTOWithStates) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [t] = useTranslation();

  const Cell = (props: TableCellProps) => <TableCell className={classes.cell} {...props} />;

  const stateIconCombinations = [
    {
      state: OrderState.NEEDS_MANUAL_INTERPRETATION,
      icon: <Eye key={OrderState.NEEDS_MANUAL_INTERPRETATION} className={classes.toBeViewedIcon} />,
    },
    {
      state: OrderState.NOT_ALL_RESULTS_AVAILABLE,
      icon: <Clock key={OrderState.NOT_ALL_RESULTS_AVAILABLE} />,
    },
    {
      state: OrderState.PATIENT_HAS_NOT_ACCESSED,
      icon: <AccountEye key={OrderState.PATIENT_HAS_NOT_ACCESSED} />,
    },
    {
      state: OrderState.IS_NOT_DOCUMENTED_IN_PIS,
      icon: <SwapHorizontal key={OrderState.IS_NOT_DOCUMENTED_IN_PIS} />,
    },
    {
      state: OrderState.IS_NOT_BILLED,
      icon: <CurrencyUsd key={OrderState.IS_NOT_BILLED} />,
    },
    {
      state: OrderState.COMPLETED,
      icon: <CheckboxMarkedCircleOutline key={OrderState.COMPLETED} className={classes.doneIcon} />,
    },
  ];

  return (
    <>
      <TableRow className={classes.tableRow} onClick={() => setOpen(!open)}>
        <Cell>
          <div className={classes.stateIcons}>
            {map(
              ({ state, icon }) =>
                includes(state, states) ? (
                  <Tooltip key={state} title={t<string>(`orderOverview.tableEntry.stateTooltip.${state}`)}>
                    {icon}
                  </Tooltip>
                ) : null,
              stateIconCombinations
            )}
          </div>
        </Cell>
        {props(columns, columnValues).map((value, index) => (
          <Cell key={index}>{value}</Cell>
        ))}
        <Cell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <ChevronUp fontSize="small" /> : <ChevronDown fontSize="small" />}
          </IconButton>
        </Cell>
      </TableRow>
      <TableRow className={classes.openRow}>
        <Cell colSpan={columns.length + 2}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <OrderDetailsContainer
              order={{ id, observations: observations, passcode, billingInfo, states, ...columnValues }}
            />
          </Collapse>
        </Cell>
      </TableRow>
    </>
  );
}
