import { FormFieldValidator, ValidationError } from 'types/form';
import { all, isEmpty } from 'ramda';

const passesRequiredValidation = <T>(value: T) => typeof value === 'string' && value.trim().length > 0;

const passesAtLeastOneIsTrueValidation = <T extends { [s: string]: boolean }>(value: T) =>
  Object.values(value).includes(true);

const passesPasscodeValidation = (value: readonly string[]) => all((element) => !isEmpty(element), value);

const createPredicateIgnoringEmptyValues =
  (predicate: (value: string) => boolean) =>
  (value: string): boolean =>
    !passesRequiredValidation(value) || predicate(value);

const createValidator =
  <T = string>(error: ValidationError, isValid: (value: T) => boolean): FormFieldValidator<T> =>
  (value: T) => ({
    isValid: isValid(value),
    error,
  });

export const requiredValidator = createValidator(ValidationError.REQUIRED, passesRequiredValidation);

export const requiredPasscodeValidator = createValidator(ValidationError.REQUIRED, passesPasscodeValidation);

export const atLeastOneIsTrueValidator = createValidator(
  ValidationError.AT_LEAST_ONE_REQUIRED,
  passesAtLeastOneIsTrueValidation
);

const regexValidator = (error: ValidationError, regex: RegExp) =>
  createValidator(
    error,
    createPredicateIgnoringEmptyValues((value) => regex.test(value))
  );

export const phoneFormatValidator = regexValidator(ValidationError.PHONE_FORMAT, /^[+]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/);
