import { createReducer } from 'deox';
import { closeDeleteDialog, openDeleteDialog } from './action';
import produce from 'immer';

const initialState: Readonly<{
  dialogIsOpen: boolean;
  orderToBeDeleted?: string;
}> = {
  dialogIsOpen: false,
};

export const orderDeleteReducer = createReducer(initialState, (handleAction) => [
  handleAction(openDeleteDialog, (state, { payload: { orderId } }) =>
    produce(state, (draft) => {
      draft.dialogIsOpen = true;
      draft.orderToBeDeleted = orderId;
    })
  ),
  handleAction(closeDeleteDialog, (state) =>
    produce(state, (draft) => {
      draft.dialogIsOpen = false;
      draft.orderToBeDeleted = undefined;
    })
  ),
]);
