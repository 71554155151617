import Keycloak from 'keycloak-js';
import { includes } from 'ramda';

export const keycloak = new Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_URL,
  realm: 'app',
  clientId: 'webapp',
});

export const getToken = (): Promise<string> =>
  new Promise((resolve, reject) => {
    keycloak
      // refresh token if the validity is less than 20 seconds
      .updateToken(20)
      .then(() => resolve(keycloak.token!))
      .catch(reject);
  });

export const checkIfIsInternalEmployee = (roles: readonly string[]) => includes('internal-employee', roles);
