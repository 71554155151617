//Infection enum needs to be in the order in which the results should be displayed, see data/selector.ts
export enum Infection {
  HIV = 'HIV',
  LUES = 'LUES',
  LUES_SCREEN = 'LUES_SCREEN',
  CHLAMYDIA = 'CHLAMYDIA',
  GONOCOCCUS = 'GONOCOCCUS',
  HEPATITIS_C = 'HEPATITIS_C',
  LIVER = 'LIVER',
  CREATININE = 'CREATININE',
  EGFR = 'EGFR',
}

export enum Interpretation {
  WAITING_FOR_RESULTS = 'WAITING_FOR_RESULTS',
  NEGATIVE = 'NEGATIVE',
  VALUES_INCREASED = 'VALUES_INCREASED',
  POSITIVE = 'POSITIVE',
  NEEDS_MANUAL_INTERPRETATION = 'NEEDS_MANUAL_INTERPRETATION',
}

export enum Language {
  GERMAN = 'GERMAN',
  ENGLISH = 'ENGLISH',
}

export enum BillingInfo {
  PATIENT_IS_PAYING = 'PATIENT_IS_PAYING',
  HEALTH_INSURANCE_IS_PAYING = 'HEALTH_INSURANCE_IS_PAYING',
  OTHER = 'OTHER',
}
