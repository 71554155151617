import { getApiClientForMethod } from 'common/api/request-builder';
import { getType } from 'deox';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { requestActionBundles, setCreatedOrder } from 'store/data/action';
import { v4 as uuid } from 'uuid';
import { endpoints } from '../../common/api/api';
import { CreateOrderDTO } from '../../types/data';
import { AxiosResponse } from 'axios';
import { routerHistory, RoutePaths } from '../../components/router/Router';
import { addAlert } from '../alert/action';
import { AlertSeverity } from '../../types/alert';
import { selectIsOrderCreateFormValid, selectOrderCreateFormValues } from '../order-create/selector';
import {
  logWarningIfValidationRemarksArePresent,
  transformWithSchema,
} from '@healthinal/typescript-schema-transformer';
import { getAuthorizationHeader } from '../../common/api/getAuthHeader';
import { getToken } from '../../common/keycloak';
import { OrderFormValues } from '../../types/form';
import { OrderDTOSchema } from '../../types/schemas';
import { selectIsInternalEmployee } from '../admin-login/selector';
import { transformFormToDTO } from '../common';

function* requestSaga(): Generator<any, any, any> {
  try {
    const isValid: boolean = yield select(selectIsOrderCreateFormValid);
    if (!isValid) {
      yield put(
        requestActionBundles.createOrder.requestFailed({
          id: yield call(uuid),
          message: 'Request cannot be completed. Not all data is present in the form.',
        })
      );
      return;
    }
    const form: OrderFormValues = yield select(selectOrderCreateFormValues);
    const dto: CreateOrderDTO = transformFormToDTO(form);
    const { method, url } = endpoints.order.create();
    const result: AxiosResponse = yield call(getApiClientForMethod(method), url, dto, {
      headers: getAuthorizationHeader(yield call(getToken)),
    });
    const [order, validationRemarks] = transformWithSchema(OrderDTOSchema, result.data);
    logWarningIfValidationRemarksArePresent('order create saga', validationRemarks);
    yield put(requestActionBundles.createOrder.requestSucceeded());
    yield put(setCreatedOrder(order));
    yield call(routerHistory.push, RoutePaths.ORDER_CREATED);
    yield put(addAlert(yield call(uuid), 'orderCreate.success', AlertSeverity.SUCCESS));
    if (yield select(selectIsInternalEmployee)) {
      yield put(requestActionBundles.readPagedOrders.sendRequest());
    }
  } catch (error) {
    console.log(error);
    const id: string = yield call(uuid);
    yield put(addAlert(id, 'orderCreate.error', AlertSeverity.ERROR));
    yield put(
      requestActionBundles.createOrder.requestFailed({
        id,
        message: JSON.stringify(error),
      })
    );
  }
}

export function* orderCreateSaga() {
  yield takeLatest(getType(requestActionBundles.createOrder.sendRequest), requestSaga);
}
