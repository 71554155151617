import { call, debounce, put, select, takeLatest } from 'redux-saga/effects';
import { ActionType } from 'deox';
import { requestActionBundles, setPagedOrders } from './action';
import { v4 as uuid } from 'uuid';
import { addAlert } from '../alert/action';
import { AlertSeverity } from '../../types/alert';
import { AxiosResponse } from 'axios';
import { getApiClientForMethod } from '../../common/api/request-builder';
import { endpoints } from '../../common/api/api';
import { getAuthorizationHeader } from '../../common/api/getAuthHeader';
import { getToken } from '../../common/keycloak';
import {
  logWarningIfValidationRemarksArePresent,
  transformWithSchema,
} from '@healthinal/typescript-schema-transformer';
import { pagedOrderDetailsDTOSchema } from '../../types/schemas';
import {
  deleteAllFilters,
  setCurrentPage,
  setFilterByInterpretationNeeded,
  setFilterByPositiveInterpretation,
  setFilterByUser,
  setSearchQuery,
  setSort,
} from '../order-overview/action';
import {
  selectCurrentPage,
  selectFilterByInterpretationNeeded,
  selectFilterByPositiveInterpretation,
  selectFilterByUser,
  selectSearchQuery,
  selectSortBy,
  selectSortDirection,
} from '../order-overview/selector';
import { OVERVIEW_PAGE_SIZE } from '../../constants';
import { isEmpty } from 'ramda';
import { Interpretation } from '../../types/common';

function* requestReadPagedOrders() {
  try {
    const { method, url } = endpoints.order.readAll();
    const currentPage: number = yield select(selectCurrentPage);
    const selfAssigned: boolean = yield select(selectFilterByUser);
    const filterByPositiveInterpretation: boolean = yield select(selectFilterByPositiveInterpretation);
    const filterByInterpretationNeeded: boolean = yield select(selectFilterByInterpretationNeeded);
    const searchQuery: string = yield select(selectSearchQuery);
    const sortBy: string = yield select(selectSortBy);
    const sortDirection: string = yield select(selectSortDirection);
    const result: AxiosResponse = yield call(getApiClientForMethod(method), url, {
      params: {
        page: currentPage,
        pageSize: OVERVIEW_PAGE_SIZE,
        sortBy,
        sortDirection,
        searchQuery: isEmpty(searchQuery) ? undefined : searchQuery,
        interpretation: (filterByPositiveInterpretation ? [Interpretation.POSITIVE] : []).concat(
          filterByInterpretationNeeded ? [Interpretation.NEEDS_MANUAL_INTERPRETATION] : []
        ),
        selfAssigned: selfAssigned,
      },
      headers: getAuthorizationHeader(yield call(getToken)),
    });
    const [payload, validationRemarks] = transformWithSchema(pagedOrderDetailsDTOSchema, result.data);
    logWarningIfValidationRemarksArePresent('order read all saga', validationRemarks);
    yield put(setPagedOrders({ orders: payload.items, itemCount: payload.itemCount }));
    yield put(requestActionBundles.readPagedOrders.requestSucceeded());
  } catch (error) {
    console.log(error);
    const id: string = yield call(uuid);
    yield put(addAlert(id, 'orderOverview.error', AlertSeverity.ERROR));
    yield put(
      requestActionBundles.readPagedOrders.requestFailed({
        id,
        message: JSON.stringify(error),
      })
    );
  }
}

function* requestResendSMSSaga(action: ActionType<typeof requestActionBundles.resendSMS.sendRequest>) {
  try {
    const { method, url } = endpoints.order.resendSMS();
    yield call(
      getApiClientForMethod(method),
      url,
      { id: action.payload.orderId },
      {
        headers: getAuthorizationHeader(yield call(getToken)),
      }
    );
    yield put(requestActionBundles.resendSMS.requestSucceeded());
    yield put(addAlert(yield call(uuid), 'orderOverview.tableEntryDetails.resendSMS.success', AlertSeverity.SUCCESS));
  } catch (error) {
    console.log(error);
    const id: string = yield call(uuid);
    yield put(addAlert(id, 'orderOverview.tableEntryDetails.resendSMS.error', AlertSeverity.ERROR));
    yield put(
      requestActionBundles.resendSMS.requestFailed({
        id: yield call(uuid),
        message: JSON.stringify(error),
      })
    );
  }
}

export function* orderOverviewSaga() {
  yield takeLatest(requestActionBundles.resendSMS.sendRequest, requestResendSMSSaga);
  yield takeLatest(requestActionBundles.readPagedOrders.sendRequest, requestReadPagedOrders);
  yield takeLatest(setCurrentPage, requestReadPagedOrders);
  yield takeLatest(setFilterByUser, requestReadPagedOrders);
  yield takeLatest(setFilterByInterpretationNeeded, requestReadPagedOrders);
  yield takeLatest(setFilterByPositiveInterpretation, requestReadPagedOrders);
  yield takeLatest(deleteAllFilters, requestReadPagedOrders);
  yield takeLatest(setSort, requestReadPagedOrders);
  yield debounce(200, setSearchQuery, requestReadPagedOrders);
}
