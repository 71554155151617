import {
  optionalStringFormField,
  prefillOptionalStringFormField,
  prefillRequiredInfectionFormField,
  prefillRequiredLanguageFormField,
  prefillRequiredPhoneFormField,
  prefillRequiredStringFormField,
  requiredInfectionFormField,
  requiredLanguageFormField,
  requiredPhoneFormField,
  requiredStringFormField,
  validateRequiredInfectionFormField,
  validateRequiredLanguageFormField,
  validateRequiredPhoneFormField,
} from '../../common/form-validation/reducer';
import { Infection, Interpretation } from '../../types/common';
import { OrderForm } from '../../types/form';
import { createReducer } from 'deox';
import { setInfections, setOrderToBeUpdated, setLanguage, setPhone } from './action';
import produce from 'immer';
import { any, filter, forEach, map } from 'ramda';
import { ObservationDTO } from '../../types/data';

const initialForm = {
  kgNr: requiredStringFormField,
  phone: requiredPhoneFormField,
  infections: requiredInfectionFormField,
  language: requiredLanguageFormField,
  assignedEmployeeId: optionalStringFormField,
};

export const initialState: Readonly<{
  infectionsAlreadyProcessed: Infection[];
  form: OrderForm;
}> = {
  infectionsAlreadyProcessed: [],
  form: initialForm,
};

export const orderUpdateReducer = createReducer(initialState, (handleAction) => [
  handleAction(setPhone, (state, { payload: { phone } }) =>
    produce(state, (draft) => {
      draft.form.phone = validateRequiredPhoneFormField(phone);
    })
  ),
  handleAction(setInfections, (state, { payload: { infections, present } }) =>
    produce(state, (draft) => {
      forEach((infection) => {
        draft.form.infections.value[infection] = present;
      }, infections);
      draft.form.infections = validateRequiredInfectionFormField(draft.form.infections.value);
    })
  ),
  handleAction(setLanguage, (state, { payload: { language } }) =>
    produce(state, (draft) => {
      draft.form.language = validateRequiredLanguageFormField(language);
    })
  ),
  handleAction(
    setOrderToBeUpdated,
    (
      state,
      {
        payload: {
          order: { kgNr, phone, language, observations, assignedEmployeeId },
        },
      }
    ) =>
      produce(state, (draft) => {
        draft.infectionsAlreadyProcessed = map(
          ({ infection }) => infection,
          filter(
            (observation) => observation.interpretation !== Interpretation.WAITING_FOR_RESULTS,
            observations
          ) as ObservationDTO[]
        );
        draft.form.kgNr = prefillRequiredStringFormField(kgNr);
        draft.form.phone = prefillRequiredPhoneFormField(phone);
        draft.form.language = prefillRequiredLanguageFormField(language);
        const isPresent = (infection: Infection) =>
          any((observation) => observation.infection === infection, observations);
        draft.form.infections = prefillRequiredInfectionFormField({
          HIV: isPresent(Infection.HIV),
          LUES: isPresent(Infection.LUES),
          LUES_SCREEN: isPresent(Infection.LUES_SCREEN),
          CHLAMYDIA: isPresent(Infection.CHLAMYDIA),
          GONOCOCCUS: isPresent(Infection.GONOCOCCUS),
          LIVER: isPresent(Infection.LIVER),
          CREATININE: isPresent(Infection.CREATININE),
          EGFR: isPresent(Infection.EGFR),
          HEPATITIS_C: isPresent(Infection.HEPATITIS_C),
        });
        draft.form.assignedEmployeeId = prefillOptionalStringFormField(assignedEmployeeId ?? null);
      })
  ),
]);
