import { FormFieldSelectorResult } from '../../types/form';
import { FormControl, FormControlLabel, FormLabel, RadioGroup, Radio } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Language } from '../../types/common';
import { map, values } from 'ramda';

type Props = Readonly<{
  disabled?: boolean;
  formField: FormFieldSelectorResult;
  setLanguage: (value: Language) => unknown;
}>;

export default function LanguageRadioGroup({ disabled, formField, setLanguage }: Props) {
  const [t] = useTranslation();

  return (
    <FormControl disabled={disabled}>
      <FormLabel>{t('orderForm.language')}</FormLabel>
      <RadioGroup value={formField.value} onChange={(e) => setLanguage(e.target.value as Language)}>
        {map(
          (language) => (
            <FormControlLabel key={language} value={language} control={<Radio />} label={t(`language.${language}`)} />
          ),
          values(Language)
        )}
      </RadioGroup>
    </FormControl>
  );
}
