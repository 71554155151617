// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from 'react';
import { classNames } from '@plasmicapp/react-web';

export type HourglassIconProps = React.ComponentProps<'svg'> & {
  title?: string;
};

export function HourglassIcon(props: HourglassIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'none'}
      viewBox={'0 0 16 16'}
      height={'1em'}
      width={'1em'}
      style={{
        fill: 'currentcolor',

        ...(style || {}),
      }}
      className={classNames('plasmic-default__svg', className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          'M15 2c.6 0 1-.4 1-1s-.4-1-1-1H1C.4 0 0 .4 0 1s.4 1 1 1h1c.1 2.4.8 4.5 2 6-1.2 1.4-2 3.6-2 6H1c-.6 0-1 .4-1 1s.4 1 1 1h14c.6 0 1-.4 1-1s-.4-1-1-1h-1c-.1-2.5-.8-4.6-2-6 1.3-1.5 2-3.6 2-6h1zM9.9 7.3l-.9.8 1 .7c1.2.9 2 2.9 2.1 5.2H4c.1-2.3.9-4.3 2.1-5.2l1-.7-1-.8C4.8 6.1 4.1 4.2 4 2h8c-.1 2.2-.8 4.1-2.1 5.3z'
        }
        fill={'currentColor'}
      ></path>
    </svg>
  );
}

export default HourglassIcon;
/* prettier-ignore-end */
