import { RootState } from '../../store';
import { selectOrderFormState } from '../../store/order-create/selector';
import { Action, Dispatch } from 'redux';
import { pipe } from 'ramda';
import { setInfections, setKgNr, setLanguage, setPhone } from '../../store/order-create/action';
import { connect } from 'react-redux';
import OrderCreateForm from './OrderCreateForm';

const mapStateToProps = (state: RootState) => ({
  formState: selectOrderFormState(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  setKgNr: pipe(setKgNr, dispatch),
  setPhone: pipe(setPhone, dispatch),
  setInfections: pipe(setInfections, dispatch),
  setLanguage: pipe(setLanguage, dispatch),
});

type OwnProps = { disabled?: boolean };
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
export type Props = Readonly<OwnProps & StateProps & DispatchProps>;

export default connect(mapStateToProps, mapDispatchToProps)(OrderCreateForm);
