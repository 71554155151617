import { RootState } from '../../../store';
import {
  getCurrentPosition,
  getIsLoginPatientFormValid,
  getPatientLoginFormState,
} from '../../../store/patient-login/selector';
import { Action, Dispatch } from 'redux';
import {
  clearPasscodeLetter,
  setCurrentPosition,
  setPasscodeLetter,
  setRelativePosition,
} from '../../../store/patient-login/action';
import { connect } from 'react-redux';
import { DefaultPassCodeFormProps } from '../../plasmic/checkpoint_zurich/PlasmicPassCodeForm';
import * as React from 'react';
import PassCodeFormView from './PassCodeFormView';
import { pipe } from 'ramda';
import { requestActionBundles } from '../../../store/data/action';

const mapStateToProps = (state: RootState) => ({
  currentPosition: getCurrentPosition(state),
  formState: getPatientLoginFormState(state),
  isValid: getIsLoginPatientFormValid(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  setPosition: pipe(setCurrentPosition, dispatch),
  setRelativePosition: pipe(setRelativePosition, dispatch),
  setPasscodeLetter: pipe(setPasscodeLetter, dispatch),
  clearPasscodeLetter: pipe(clearPasscodeLetter, dispatch),
  loginToPatientReport: pipe(requestActionBundles.loginToPatientReport.sendRequest, dispatch),
});

type OwnProps = Readonly<{
  disabled?: boolean;
}>;
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
export type Props = Readonly<OwnProps & DefaultPassCodeFormProps & StateProps & DispatchProps>;

export default connect(mapStateToProps, mapDispatchToProps)(React.forwardRef(PassCodeFormView));
