import * as React from 'react';
import { LegacyRef } from 'react';
import PlasmicButton, { DefaultButtonProps } from '../plasmic/checkpoint_zurich/PlasmicButton';

type Props = DefaultButtonProps &
  Readonly<{
    onClick: () => void;
  }>;

const Button = ({ onClick, ...rest }: Props, ref: LegacyRef<HTMLButtonElement>) => (
  <PlasmicButton onClick={onClick} root={{ ref }} {...rest} />
);

export default React.forwardRef(Button);
