import { getRequestBuilder } from './request-builder';

const requestBuilder = getRequestBuilder('/api');

export const endpoints = {
  order: {
    create: () => requestBuilder.post('admin/order'),
    readAll: () => requestBuilder.get('admin/order'),
    update: (id: string) => requestBuilder.put(`admin/order/${id}`),
    delete: (id: string) => requestBuilder.delete(`admin/order/${id}`),
    resendSMS: () => requestBuilder.post('admin/order/sms'),
  },
  observation: {
    update: () => requestBuilder.put('admin/observation'),
  },
  users: {
    readAllWithRole: () => requestBuilder.get('admin/user'),
  },
  patientReport: {
    read: () => requestBuilder.post('patient/report'),
  },
};
