import {
  optionalStringFormField,
  requiredInfectionFormField,
  requiredLanguageFormField,
  requiredPhoneFormField,
  requiredStringFormField,
  validateOptionalStringFormField,
  validateRequiredInfectionFormField,
  validateRequiredLanguageFormField,
  validateRequiredPhoneFormField,
  validateRequiredStringFormField,
} from '../../common/form-validation/reducer';
import { OrderForm } from '../../types/form';
import { createReducer } from 'deox';
import { clearForm, setAssignedEmployeeId, setInfections, setKgNr, setLanguage, setPhone } from './action';
import produce from 'immer';
import { forEach } from 'ramda';
import { setCreatedOrder } from '../data/action';

const initialForm = {
  kgNr: requiredStringFormField,
  phone: requiredPhoneFormField,
  infections: requiredInfectionFormField,
  language: requiredLanguageFormField,
  assignedEmployeeId: optionalStringFormField,
};

export const initialState: Readonly<{
  form: OrderForm;
}> = {
  form: initialForm,
};

export const orderCreateReducer = createReducer(initialState, (handleAction) => [
  handleAction(setKgNr, (state, { payload: { kgNr } }) =>
    produce(state, (draft) => {
      draft.form.kgNr = validateRequiredStringFormField(kgNr);
    })
  ),
  handleAction(setPhone, (state, { payload: { phone } }) =>
    produce(state, (draft) => {
      draft.form.phone = validateRequiredPhoneFormField(phone);
    })
  ),
  handleAction(setInfections, (state, { payload: { infections, present } }) =>
    produce(state, (draft) => {
      forEach((infection) => {
        draft.form.infections.value[infection] = present;
      }, infections);
      draft.form.infections = validateRequiredInfectionFormField(draft.form.infections.value);
    })
  ),
  handleAction(setLanguage, (state, { payload: { language } }) =>
    produce(state, (draft) => {
      draft.form.language = validateRequiredLanguageFormField(language);
    })
  ),
  handleAction(setAssignedEmployeeId, (state, { payload: { userId } }) =>
    produce(state, (draft) => {
      draft.form.assignedEmployeeId = validateOptionalStringFormField(userId);
    })
  ),
  handleAction(clearForm, (state) =>
    produce(state, (draft) => {
      draft.form = initialForm;
    })
  ),
  handleAction(
    setCreatedOrder,
    (
      state,
      {
        payload: {
          order: { kgNr, phone, infections, assignedEmployeeId },
        },
      }
    ) =>
      produce(state, (draft) => {
        draft.form.kgNr = validateRequiredStringFormField(kgNr);
        draft.form.phone = validateRequiredPhoneFormField(phone);
        forEach((infection) => {
          draft.form.infections.value[infection] = true;
        }, infections);
        draft.form.infections = validateRequiredInfectionFormField(draft.form.infections.value);
        draft.form.assignedEmployeeId = validateOptionalStringFormField(assignedEmployeeId ?? null);
      })
  ),
]);
