import { RootState } from '../index';
import { pipe } from 'ramda';

const selectObservationInterpretState = ({ observationInterpret }: RootState) => observationInterpret;
export const selectIsInterpretationMessageDialogOpen = pipe(
  selectObservationInterpretState,
  ({ dialogIsOpen }) => dialogIsOpen
);
export const selectObservationIdToBeInterpreted = pipe(
  selectObservationInterpretState,
  ({ observationId }) => observationId
);
export const selectInfection = pipe(selectObservationInterpretState, ({ infection }) => infection);
export const selectMessage = pipe(selectObservationInterpretState, ({ message }) => message);
