import { FormField, FormFieldValidator, FormFieldValidatorResult, ValidationError } from '../../types/form';
import { atLeastOneIsTrueValidator, phoneFormatValidator, requiredValidator } from './validators';
import { Infection, Language } from '../../types/common';

const getValidationError = ({ isValid, error }: FormFieldValidatorResult) => (isValid ? [] : [error]);

const getValidationErrors = <T = string>(value: T, validators: FormFieldValidator<T>[]) =>
  validators.reduce(
    (errors, validator) => [...errors, ...getValidationError(validator(value))],
    [] as ValidationError[]
  );

export const initFormField = <T = string>(value: T, validators: FormFieldValidator<T>[] = []): FormField<T> => ({
  value,
  isDirty: false,
  errors: getValidationErrors(value, validators),
});

export const validateFormField = <T = string>(value: T, validators: FormFieldValidator<T>[] = []): FormField<T> => ({
  value,
  isDirty: true,
  errors: getValidationErrors(value, validators),
});

export const createFormFieldHandlers = <T = string>(value: T, validators: FormFieldValidator<T>[] = []) => ({
  init: initFormField(value, validators),
  prefill: (value: T) => initFormField(value, validators),
  validate: (value: T) => validateFormField(value, validators),
});

export const {
  init: requiredStringFormField,
  prefill: prefillRequiredStringFormField,
  validate: validateRequiredStringFormField,
} = createFormFieldHandlers<string>('', [requiredValidator]);

export const {
  init: optionalStringFormField,
  prefill: prefillOptionalStringFormField,
  validate: validateOptionalStringFormField,
} = createFormFieldHandlers<string | null>(null);

export const {
  init: requiredPhoneFormField,
  prefill: prefillRequiredPhoneFormField,
  validate: validateRequiredPhoneFormField,
} = createFormFieldHandlers<string>('', [requiredValidator, phoneFormatValidator]);

export const {
  init: requiredLanguageFormField,
  prefill: prefillRequiredLanguageFormField,
  validate: validateRequiredLanguageFormField,
} = createFormFieldHandlers<Language>(Language.GERMAN, [requiredValidator]);

export const {
  init: requiredInfectionFormField,
  prefill: prefillRequiredInfectionFormField,
  validate: validateRequiredInfectionFormField,
} = createFormFieldHandlers<{ [infection in Infection]: boolean }>(
  {
    HIV: false,
    LUES: false,
    LUES_SCREEN: false,
    CHLAMYDIA: false,
    GONOCOCCUS: false,
    LIVER: false,
    CREATININE: false,
    EGFR: false,
    HEPATITIS_C: false,
  },
  [atLeastOneIsTrueValidator]
);
