import * as React from 'react';
import { PlasmicLogin } from '../plasmic/checkpoint_zurich/PlasmicLogin';
import { HTMLElementRefOf } from '@plasmicapp/react-web';
import { Props } from './Login';
import { useTranslation } from 'react-i18next';
import { useAccessToken } from '../../hooks/useAccessToken';
import { Navigate } from 'react-router-dom';
import { RoutePaths } from '../router/Router';

const LoginView = ({ isValid, loginToPatientReport, ...rest }: Props, ref: HTMLElementRefOf<'div'>) => {
  const accessToken = useAccessToken();
  const { t } = useTranslation();

  if (!accessToken) {
    return <Navigate to={RoutePaths.INVALID_LINK} />;
  }

  return (
    <PlasmicLogin
      {...rest}
      title={t('patientLogin.title')}
      text={t('patientLogin.text')}
      button={{ onClick: () => loginToPatientReport(accessToken) }}
      state={isValid ? 'filled' : undefined}
      root={{ ref }}
    />
  );
};

export default LoginView;
