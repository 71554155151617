import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';
import { Props } from './OrderDeleteDialogContainer';

export function OrderDeleteDialog({ isOpen, id, closeDeleteDialog, deleteOrder, isDeletingOrder }: Props) {
  const [t] = useTranslation();

  return (
    <Dialog open={isOpen} onClose={() => closeDeleteDialog()}>
      <DialogTitle>{t('orderDelete.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('orderDelete.text')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <SecondaryButton translationKey="orderDelete.abort" onClick={closeDeleteDialog} />
        <PrimaryButton
          translationKey="orderDelete.confirm"
          onClick={() => deleteOrder(id ?? '')}
          isLoading={isDeletingOrder}
        />
      </DialogActions>
    </Dialog>
  );
}
