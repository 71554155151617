import { RootState } from '../index';
import { map, pipe, values } from 'ramda';
import { FormState, OrderFormValues } from '../../types/form';
import {
  selectFormFieldValue,
  selectFormFieldViewValues,
  selectIsFormValid,
} from '../../common/form-validation/selector';

const selectOrderUpdateState = ({ orderUpdate }: RootState) => orderUpdate;

export const selectInfectionsAlreadyProcessed = pipe(
  selectOrderUpdateState,
  ({ infectionsAlreadyProcessed }) => infectionsAlreadyProcessed
);
const selectForm = pipe(selectOrderUpdateState, ({ form }) => form);

export const selectOrderUpdateFormState = pipe(
  selectForm,
  map(selectFormFieldViewValues) as any as (
    form: ReturnType<typeof selectForm>
  ) => FormState<ReturnType<typeof selectForm>>
);

const mapToFormFieldValues: (form: any) => OrderFormValues = map(selectFormFieldValue) as any;

export const selectOrderUpdateFormValues = pipe(selectForm, mapToFormFieldValues);

export const selectIsOrderUpdateFormValid: (rootState: RootState) => boolean = pipe(
  selectForm,
  values,
  selectIsFormValid
);
