import { Container, makeStyles, Theme } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import MainNav from '../app-bar/MainNav';
import { keycloak } from '../../common/keycloak';
import PrimaryButton from '../buttons/PrimaryButton';
import PassCodeForm from '../patient-login/passcode-form/PassCodeForm';
import OrderCreateFormContainer from '../order-create/OrderCreateFormContainer';
import { Props } from './OrderCreatedContainer';
import { RoutePaths } from '../router/Router';

const useStyles = makeStyles((theme: Theme) => ({
  passCodeWrapper: {
    marginTop: theme.spacing(3.5),
    maxWidth: '530px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: theme.spacing(3.5),
  },
  buttonWrapper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default function OrderCreated({ isInternalEmployee, clearForm }: Props) {
  const navigate = useNavigate();
  const classes = useStyles();

  return (
    <>
      <MainNav loggedIn={true} doctorView={true} onLogout={keycloak.logout} />
      <Container maxWidth={'lg'}>
        <div className={classes.passCodeWrapper}>
          <PassCodeForm disabled={true} isCard={true} />
        </div>
        <OrderCreateFormContainer disabled={true} />
        <div className={classes.buttonWrapper}>
          <PrimaryButton
            translationKey={
              isInternalEmployee ? 'orderCreated.backToOverviewButton' : 'orderCreated.backToCreateButton'
            }
            onClick={() => {
              clearForm();
              navigate(RoutePaths.ORDER_OVERVIEW);
            }}
          />
        </div>
      </Container>
    </>
  );
}
