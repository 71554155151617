import { Checkbox, FormControlLabel, makeStyles } from '@material-ui/core';
import { Infection } from '../../types/common';
import { all, pick, values } from 'ramda';

const useStyles = makeStyles({
  element: {
    width: '100%',
  },
});

type Props = {
  elements: readonly Infection[];
  label: string;
  fieldValues: { [infection in Infection]: boolean };
  setValues?: (payload: { infections: readonly Infection[]; present: boolean }) => unknown;
  disabled?: boolean;
};

const InfectionCheckboxControl = ({ elements, label, fieldValues, setValues, disabled }: Props) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={all((value) => value, values(pick(elements, fieldValues))) ?? false}
          onChange={({ target }) => setValues?.({ infections: elements, present: target.checked })}
        />
      }
      label={label}
      className={classes.element}
      disabled={disabled}
    />
  );
};

export default InfectionCheckboxControl;
