import * as React from 'react';
import { useState } from 'react';
import { DefaultResultCardProps, PlasmicResultCard } from '../plasmic/checkpoint_zurich/PlasmicResultCard';
import { HTMLElementRefOf } from '@plasmicapp/react-web';
import { Infection, Interpretation } from '../../types/common';
import { PatientResultDTO } from '../../types/data';
import { useTranslation } from 'react-i18next';
import Link from './Link';
import { TFunction } from 'i18next';
import ReactMarkdown from 'react-markdown';

const BOOKING_URL = 'https://www.cpzh.ch/booking-sti-5jh82hg/';

const mapInterpretationToUIRepresentation = (
  interpretation: Interpretation
): DefaultResultCardProps['interpretation'] => {
  switch (interpretation) {
    case Interpretation.POSITIVE:
      return 'positive';
    case Interpretation.VALUES_INCREASED:
      return 'valuesIncreased';
    case Interpretation.NEGATIVE:
      return 'negative';
    case Interpretation.NEEDS_MANUAL_INTERPRETATION:
    case Interpretation.WAITING_FOR_RESULTS:
      return 'waitingForResults';
  }
};

const showOrHideValue = (infection: Infection, interpretation: Interpretation, value: string | undefined) => {
  if (
    interpretation === Interpretation.WAITING_FOR_RESULTS ||
    interpretation === Interpretation.NEEDS_MANUAL_INTERPRETATION
  ) {
    return null;
  }
  switch (infection) {
    case Infection.LIVER:
    case Infection.EGFR:
    case Infection.CREATININE:
      return value;
    default:
      return null;
  }
};

const showInfo = (infection: Infection, interpretation: Interpretation) => {
  if (
    interpretation === Interpretation.WAITING_FOR_RESULTS ||
    interpretation === Interpretation.NEEDS_MANUAL_INTERPRETATION
  ) {
    return false;
  }
  switch (infection) {
    case Infection.LIVER:
    case Infection.EGFR:
    case Infection.CREATININE:
      return true;
    default:
      return false;
  }
};

const mapToMessage = ({ interpretation, message, infection }: PatientResultDTO, t: TFunction) => {
  if (
    interpretation === Interpretation.WAITING_FOR_RESULTS ||
    interpretation === Interpretation.NEEDS_MANUAL_INTERPRETATION
  ) {
    return t(`patientResult.interpretation.pending`);
  } else {
    return message && infection !== Infection.HIV
      ? message
      : t(`patientResult.interpretation.${interpretation}.${infection}`);
  }
};

type Props = DefaultResultCardProps &
  Readonly<{
    result: PatientResultDTO;
  }>;

function ResultCard({ result, ...rest }: Props, ref: HTMLElementRefOf<'div'>) {
  const [infoIsOpen, setInfoIsOpen] = useState(false);
  const { infection, interpretation, value } = result;
  const { t } = useTranslation();
  const hasPhoneNr = infection === Infection.HIV && interpretation === Interpretation.VALUES_INCREASED;

  return (
    <PlasmicResultCard
      button={{ onClick: () => window.open(BOOKING_URL, '_blank') }}
      infoButton={{ onClick: () => setInfoIsOpen(!infoIsOpen) }}
      infoIsOpen={infoIsOpen}
      root={{ ref }}
      interpretation={mapInterpretationToUIRepresentation(interpretation)}
      title={t(`patientResult.${infection}`)}
      value={showOrHideValue(infection, interpretation, value)}
      text={<ReactMarkdown>{mapToMessage(result, t)}</ReactMarkdown>}
      hasInfo={showInfo(infection, interpretation)}
      infoText={showInfo(infection, interpretation) ? t(`patientResult.infoText.${infection}`) : null}
      hasButton={interpretation === Interpretation.POSITIVE}
      hasPhoneNr={hasPhoneNr}
      number={
        hasPhoneNr ? (
          <Link destination={'tel:' + t(`patientResult.phoneNr`)} textValue={t(`patientResult.phoneNr`)} />
        ) : null
      }
      {...rest}
    />
  );
}

export default React.forwardRef(ResultCard);
