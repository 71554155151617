import * as React from 'react';
import { useEffect } from 'react';
import { PlasmicDashboard } from '../plasmic/checkpoint_zurich/PlasmicDashboard';
import { HTMLElementRefOf } from '@plasmicapp/react-web';
import { Props } from './Dashboard';
import ResultCard from './ResultCard';
import { invalidateStoredPasscode } from '../../common/patient-credentials';
import { routerHistory, RoutePaths } from '../router/Router';
import { useTranslation } from 'react-i18next';

const DashboardView = ({ patientReport, refetchPatientReport, ...rest }: Props, ref: HTMLElementRefOf<'div'>) => {
  useEffect(
    () => {
      if (!patientReport) {
        refetchPatientReport();
      }
    },
    // to run the effect only once, we need the empty dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const [t] = useTranslation();

  return (
    <PlasmicDashboard
      name={patientReport ? `${patientReport.firstName ?? ''} ${patientReport.lastName ?? ''}` : ''}
      title={t('patientResult.title')}
      birthdate={patientReport?.dateOfBirth ?? ''}
      divider={patientReport?.firstName && patientReport?.lastName && patientReport?.dateOfBirth ? ' – ' : ''}
      date={patientReport?.created}
      testedAt={t('patientResult.testedAt')}
      root={{ ref }}
      mainNav={{
        onLogout: () => {
          invalidateStoredPasscode();
          routerHistory.push(RoutePaths.PATIENT_LOGIN);
        },
      }}
      results={patientReport?.results.map((result, index) => <ResultCard result={result} key={index} />) ?? null}
      {...rest}
    />
  );
};

export default DashboardView;
