import { makeStyles } from '@material-ui/core';
import { FormFieldSelectorResult, ValidationError } from '../../types/form';

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: theme.typography.pxToRem(11),
  },
}));

type Props = {
  readonly formField: FormFieldSelectorResult<any>;
};

const ErrorMessage = ({ formField }: Props) => {
  const classes = useStyles();

  return formField.showAsInvalid ? (
    <div className={classes.errorMessage}>
      {formField.errors[0] === ValidationError.REQUIRED
        ? 'Dieses Feld muss ausgefüllt werden.'
        : formField.errors[0] === ValidationError.PHONE_FORMAT
        ? 'Ist keine gültige Telefonnummer.'
        : formField.errors[0] === ValidationError.AT_LEAST_ONE_REQUIRED
        ? 'Mindestens ein Feld muss ausgewählt sein'
        : ''}
    </div>
  ) : (
    <></>
  );
};

export default ErrorMessage;
