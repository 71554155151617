import * as React from 'react';
import { DefaultInvalidLinkProps, PlasmicInvalidLink } from '../plasmic/checkpoint_zurich/PlasmicInvalidLink';
import { HTMLElementRefOf } from '@plasmicapp/react-web';
import { useTranslation } from 'react-i18next';

const InvalidLink = (props: DefaultInvalidLinkProps, ref: HTMLElementRefOf<'div'>) => {
  const { t } = useTranslation();

  return <PlasmicInvalidLink title={t('invalidLink.title')} text={t('invalidLink.text')} root={{ ref }} {...props} />;
};

export default React.forwardRef(InvalidLink);
