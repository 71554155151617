import { RootState } from '../../store';
import { selectOrderedPatientReport } from '../../store/data/selector';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { DefaultDashboardProps } from '../plasmic/checkpoint_zurich/PlasmicDashboard';
import React from 'react';
import DashboardView from './DashboardView';
import { requestActionBundles } from '../../store/data/action';
import { pipe } from 'ramda';

const mapStateToProps = (state: RootState) => ({
  patientReport: selectOrderedPatientReport(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  refetchPatientReport: pipe(requestActionBundles.refetchPatientReport.sendRequest, dispatch),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
export type Props = Readonly<DefaultDashboardProps & StateProps & DispatchProps>;

export default connect(mapStateToProps, mapDispatchToProps)(React.forwardRef(DashboardView));
