import { makeStyles, TextField as MuiTextField, Theme } from '@material-ui/core';
import { FormFieldSelectorResult } from '../../types/form';
import ErrorMessage from './ErrorMessage';

const useStyles = makeStyles((theme: Theme) => ({
  textFieldWrapper: {
    marginBottom: theme.spacing(2),
  },
}));

type Props = Readonly<{
  label: string;
  formField: FormFieldSelectorResult;
  setValue?: (value: string) => unknown;
  formType?: string;
  disabled?: boolean;
}>;

const TextField = ({ formField, label, setValue, formType, disabled }: Props) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.textFieldWrapper}>
        <MuiTextField
          fullWidth={true}
          label={label}
          type={formType}
          value={formField.value}
          onChange={(e) => setValue?.(e.target.value)}
          disabled={disabled}
        />
      </div>
      <ErrorMessage formField={formField} />
    </>
  );
};

export default TextField;
