import { Action, Dispatch } from 'redux';
import { pipe } from 'ramda';
import { setSearchQuery } from '../../store/order-overview/action';
import { RootState } from '../../store';
import { connect } from 'react-redux';
import SearchBar from './SearchBar';
import { selectSearchQuery } from '../../store/order-overview/selector';

const mapStateToProps = (state: RootState) => ({
  searchQuery: selectSearchQuery(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  setSearchQuery: pipe(setSearchQuery, dispatch),
});

type OwnProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
export type Props = Readonly<OwnProps & StateProps & DispatchProps>;

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
