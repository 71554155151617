import { Container, makeStyles, Theme } from '@material-ui/core';
import { Props } from './OrderCreateContainer';
import { useNavigate } from 'react-router-dom';
import MainNav from '../app-bar/MainNav';
import { keycloak } from '../../common/keycloak';
import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';
import OrderCreateFormContainer from './OrderCreateFormContainer';
import { RoutePaths } from '../router/Router';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(3.5),
  },
  actions: { marginTop: theme.spacing(2), display: 'flex', justifyContent: 'end', gap: theme.spacing(1) },
}));

export default function OrderCreate({ isValid, isCreatingOrder, isInternalEmployee, createOrder }: Props) {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <>
      <MainNav loggedIn={true} doctorView={true} onLogout={keycloak.logout} />
      <Container maxWidth={'lg'} className={classes.container}>
        <OrderCreateFormContainer />
        <div className={classes.actions}>
          {isInternalEmployee && (
            <SecondaryButton
              translationKey={'orderCreate.cancelButton'}
              onClick={() => navigate(RoutePaths.ORDER_OVERVIEW)}
            />
          )}
          <PrimaryButton
            translationKey={'orderCreate.createButton'}
            onClick={createOrder}
            disabled={!isValid}
            isLoading={isCreatingOrder}
          />
        </div>
      </Container>
    </>
  );
}
