// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from 'react';
import { classNames } from '@plasmicapp/react-web';

export type SmallChevronLeftIconProps = React.ComponentProps<'svg'> & {
  title?: string;
};

export function SmallChevronLeftIcon(props: SmallChevronLeftIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'none'}
      viewBox={'0 0 16 16'}
      height={'1em'}
      width={'1em'}
      style={{
        fill: 'currentcolor',

        ...(style || {}),
      }}
      className={classNames('plasmic-default__svg', className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={'evenodd'}
        clipRule={'evenodd'}
        d={
          'M6.46 8.006c.116.152.308.348.661.701l3.586 3.586a1 1 0 01-1.414 1.414l-3.586-3.586a46.486 46.486 0 01-.038-.038c-.3-.3-.592-.591-.8-.864-.234-.307-.455-.705-.455-1.219 0-.514.22-.912.455-1.219.208-.273.5-.564.8-.864l.038-.038 3.586-3.586a1 1 0 011.414 1.414L7.122 7.292c-.354.354-.546.55-.663.702L6.455 8l.004.006z'
        }
        fill={'currentColor'}
      ></path>
    </svg>
  );
}

export default SmallChevronLeftIcon;
/* prettier-ignore-end */
