import { combineReducers } from 'redux';
import { dataReducer } from './data/reducer';
import { all } from 'redux-saga/effects';
import { orderCreateSaga } from './data/order-create-saga';
import { orderCreateReducer } from './order-create/reducer';
import { alertReducer } from './alert/reducer';
import { patientLoginReducer } from './patient-login/reducer';
import { patientReportSaga } from './data/patient-report-saga';
import { observationInterpretSaga } from './data/observation-interpret-saga';
import { orderUpdateSaga } from './data/order-update-saga';
import { orderOverviewSaga } from './data/order-overview-saga';
import { orderOverviewReducer } from './order-overview/reducer';
import { adminLoginReducer } from './admin-login/reducer';
import { adminLoginSaga } from './admin-login/saga';
import { orderDeleteReducer } from './order-delete/reducer';
import { orderDeleteSaga } from './data/order-delete-saga';
import { orderUpdateReducer } from './order-update/reducer';
import { observationInterpretReducer } from './observation-interpret/reducer';
import { usersReadSaga } from './data/users-read-saga';

export const rootReducer = combineReducers({
  adminLogin: adminLoginReducer,
  data: dataReducer,
  orderCreate: orderCreateReducer,
  orderUpdate: orderUpdateReducer,
  orderDelete: orderDeleteReducer,
  orderOverview: orderOverviewReducer,
  observationInterpret: observationInterpretReducer,
  patientReport: patientLoginReducer,
  alert: alertReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export function* rootSaga() {
  yield all([
    adminLoginSaga(),
    orderCreateSaga(),
    orderUpdateSaga(),
    orderDeleteSaga(),
    orderOverviewSaga(),
    observationInterpretSaga(),
    patientReportSaga(),
    usersReadSaga(),
  ]);
}
