import { RootState } from '../../store';
import { Action, Dispatch } from 'redux';
import { requestActionBundles } from '../../store/data/action';
import { connect } from 'react-redux';
import { selectPagedOrdersWithStates } from '../../store/data/selector';
import { pipe } from 'ramda';
import {
  deleteAllFilters,
  setCurrentPage,
  setFilterByInterpretationNeeded,
  setFilterByPositiveInterpretation,
  setFilterByUser,
} from '../../store/order-overview/action';
import {
  selectFilterByInterpretationNeeded,
  selectFilterByPositiveInterpretation,
  selectFilterByUser,
} from '../../store/order-overview/selector';
import OrderOverview from './OrderOverview';

const mapStateToProps = (state: RootState) => ({
  allLabReports: selectPagedOrdersWithStates(state),
  filterByUser: selectFilterByUser(state),
  filterByInterpretationNeeded: selectFilterByInterpretationNeeded(state),
  filterByPositiveInterpretation: selectFilterByPositiveInterpretation(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  setPage: pipe(setCurrentPage, dispatch),
  updateOrder: pipe(requestActionBundles.updateOrderWithFormData.sendRequest, dispatch),
  setFilterByUser: pipe(setFilterByUser, dispatch),
  setFilterByInterpretationNeeded: pipe(setFilterByInterpretationNeeded, dispatch),
  setFilterByPositiveInterpretation: pipe(setFilterByPositiveInterpretation, dispatch),
  deleteAllFilters: pipe(deleteAllFilters, dispatch),
});

type OwnProps = {};
type StateProps = Readonly<ReturnType<typeof mapStateToProps>>;
type DispatchProps = Readonly<ReturnType<typeof mapDispatchToProps>>;
export type Props = OwnProps & StateProps & DispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(OrderOverview);
