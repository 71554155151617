import { RootState } from '../../store';
import { Action, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { requestActionBundles } from '../../store/data/action';
import { selectIsOrderCreateFormValid, selectOrderFormState } from '../../store/order-create/selector';
import { pipe } from 'ramda';
import { selectIsInternalEmployee } from '../../store/admin-login/selector';
import { selectIsCreatingOrder } from '../../store/data/selector';
import OrderCreate from './OrderCreate';

const mapStateToProps = (state: RootState) => ({
  isValid: selectIsOrderCreateFormValid(state),
  formState: selectOrderFormState(state),
  isCreatingOrder: selectIsCreatingOrder(state),
  isInternalEmployee: selectIsInternalEmployee(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  createOrder: pipe(requestActionBundles.createOrder.sendRequest, dispatch),
});

type OwnProps = {};
type StateProps = Readonly<ReturnType<typeof mapStateToProps>>;
type DispatchProps = Readonly<ReturnType<typeof mapDispatchToProps>>;
export type Props = OwnProps & StateProps & DispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(OrderCreate);
