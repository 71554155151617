import { Checkbox, CircularProgress, FormControlLabel, makeStyles, Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export type Props = {
  orderId: string;
  isChecked: boolean;
  translationKey: string;
  isDisabled: boolean;
  onChange: (orderId: string, isChecked: boolean) => unknown;
  isUpdating: () => boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    minWidth: 200,
    marginBottom: theme.spacing(1),
  },
  label: {
    fontSize: theme.typography.pxToRem(14),
  },
  labelContent: {
    display: 'flex',
    alignItems: 'center',
  },
  progress: {
    marginLeft: theme.spacing(1),
    display: 'flex',
  },
}));

export function StateCheckbox({ orderId, isChecked, translationKey, onChange, isDisabled, isUpdating }: Props) {
  const [t] = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <FormControlLabel
        control={
          <Checkbox
            color={'primary'}
            checked={isChecked}
            onChange={({ target }) => onChange(orderId, target.checked)}
            size={'small'}
          />
        }
        disabled={isDisabled}
        classes={{ label: classes.label }}
        label={
          <div className={classes.labelContent}>
            <div>
              <span>{t(`orderOverview.tableEntryDetails.${translationKey}`)}</span>
            </div>
            {isUpdating() && (
              <div className={classes.progress}>
                <CircularProgress size={14} />
              </div>
            )}
          </div>
        }
      />
    </div>
  );
}
