import { createActionCreator } from 'deox';
import { Infection, Language } from '../../types/common';
import { OrderDetailsDTO } from '../../types/data';

export const setPhone = createActionCreator(
  'OrderUpdate/SET_PHONE',
  (resolve) => (phone: string) => resolve({ phone })
);

export const setInfections = createActionCreator(
  'OrderUpdate/SET_INFECTIONS',
  (resolve) => (payload: { infections: readonly Infection[]; present: boolean }) => resolve(payload)
);

export const setLanguage = createActionCreator(
  'OrderUpdate/SET_LANGUAGE',
  (resolve) => (language: Language) => resolve({ language })
);

export const setOrderToBeUpdated = createActionCreator(
  'OrderUpdate/SET_ORDER_TO_BE_UPDATED',
  (resolve) => (order: OrderDetailsDTO) => resolve({ order })
);
