import axios, { AxiosPromise, AxiosRequestConfig } from "axios";

export enum Method {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  PATCH = "PATCH",
  DELETE = "DELETE",
}

export interface Endpoint {
  readonly url: string;
  readonly method: Method;
}

export interface PathParams {
  [key: string]: string;
}

export const replacePathParams = (path: string, params: PathParams): string =>
  Object.keys(params).reduce(
    (newPath, key) =>
      newPath.replace(`{${key}}`, encodeURIComponent(params[key])),
    path
  );

export const createEndpoint =
  (url: string, method: Method) =>
  (path: string, params?: PathParams): Endpoint => ({
    url: `${url}/${replacePathParams(path, params || {})}`,
    method,
  });

export const getRequestBuilder = (apiUrl: string) => ({
  get: createEndpoint(apiUrl, Method.GET),
  post: createEndpoint(apiUrl, Method.POST),
  put: createEndpoint(apiUrl, Method.PUT),
  patch: createEndpoint(apiUrl, Method.PATCH),
  delete: createEndpoint(apiUrl, Method.DELETE),
});

export const getApiClientForMethod = <T = any>(
  method: Method
):
  | ((url: string, config?: AxiosRequestConfig) => AxiosPromise<T>)
  | ((
      url: string,
      data?: any,
      config?: AxiosRequestConfig
    ) => AxiosPromise<T>) => {
  switch (method) {
    case Method.POST:
      return axios.post;
    case Method.PUT:
      return axios.put;
    case Method.PATCH:
      return axios.patch;
    case Method.DELETE:
      return axios.delete;
    case Method.GET:
    default:
      return axios.get;
  }
};
