import { createRequestActionsCreator } from 'common/request-action';
import { createActionCreator } from 'deox';
import { map, values } from 'ramda';
import { OrderDTO, ObservationDTO, PatientReportDTO, OrderDetailsDTO, UserDTO } from '../../types/data';
import { Interpretation } from '../../types/common';

const createRequestActions = createRequestActionsCreator('data');

export const setCreatedOrder = createActionCreator(
  'data/SET_CREATED_ORDER',
  (resolve) => (order: OrderDTO) => resolve({ order })
);

export const setUpdatedOrder = createActionCreator(
  'data/SET_UPDATED_ORDER',
  (resolve) => (order: OrderDetailsDTO) => resolve({ order })
);

export const setPagedOrders = createActionCreator(
  'data/SET_PAGED_ORDERS',
  (resolve) => (payload: { orders: readonly OrderDetailsDTO[]; itemCount: number }) => resolve(payload)
);

export const setInterpretedObservation = createActionCreator(
  'data/SET_INTERPRETED_OBSERVATION',
  (resolve) => (observation: ObservationDTO) => resolve({ observation })
);

export const setPatientReport = createActionCreator(
  'data/SET_PATIENT_REPORT',
  (resolve) => (patientReport: PatientReportDTO) => resolve({ patientReport })
);

export const setUpdateOrderForm = createActionCreator(
  'data/SET_ORDER_FORM',
  (resolve) => (orderId: string) => resolve({ orderId })
);

export const setUsers = createActionCreator(
  'data/SET_USERS',
  (resolve) => (users: readonly UserDTO[]) => resolve({ users })
);

export const requestActionBundles = {
  createOrder: createRequestActions('CREATE_ORDER'),
  readPagedOrders: createRequestActions('READ_PAGED_ORDERS'),
  updateOrderIsDocumentedInPIS: createRequestActions(
    'UPDATE_ORDER_IS_DOCUMENTED_IN_PIS',
    (resolve) => (payload: { orderId: string; isDocumentedInPIS: boolean }) => resolve(payload)
  ),
  updateOrderIsBilled: createRequestActions(
    'UPDATE_ORDER_IS_BILLED',
    (resolve) => (payload: { orderId: string; isBilled: boolean }) => resolve(payload)
  ),
  updateOrderWithFormData: createRequestActions(
    'UPDATE_ORDER_WITH_FORM_DATA',
    (resolve) => (orderId: string) => resolve({ orderId })
  ),
  deleteOrder: createRequestActions('DELETE_ORDER', (resolve) => (orderId: string) => resolve({ orderId })),
  loginToPatientReport: createRequestActions(
    'LOGIN_TO_PATIENT_REPORT',
    (resolve) => (accessToken: string) => resolve({ accessToken })
  ),
  refetchPatientReport: createRequestActions('RELOAD_PATIENT_REPORT'),
  interpretObservation: createRequestActions(
    'INTERPRET_ORDER',
    (resolve) => (payload: { id: string; interpretation: Interpretation; message: string | null }) => resolve(payload)
  ),
  resendSMS: createRequestActions('RESEND_SMS', (resolve) => (orderId: string) => resolve({ orderId })),
  readAllInternalUsers: createRequestActions('READ_ALL_INTERNAL_USERS'),
};

const bundlesAsArray = values(requestActionBundles);
export const sendRequestActions = map(({ sendRequest }) => sendRequest, bundlesAsArray);
export const requestSucceededActions = map(({ requestSucceeded }) => requestSucceeded, bundlesAsArray);
export const requestFailedActions = map(({ requestFailed }) => requestFailed, bundlesAsArray);
