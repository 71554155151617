import { Router as ReactRouter, Navigate, Route, Routes } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import AppAlertsContainer from '../app-alerts/AppAlertsContainer';
import { SnackbarProvider } from 'notistack';
import Login from '../patient-login/Login';
import Dashboard from '../patient-dashboard/Dashboard';
import InvalidLink from '../patient-login/InvalidLink';
import { Props } from './RouterContainer';
import OrderCreateContainer from '../order-create/OrderCreateContainer';
import OrderUpdateContainer from '../order-update/OrderUpdateContainer';
import OrderCreatedContainer from '../order-created/OrderCreatedContainer';
import OrderOverviewContainer from '../order-overview/OrderOverviewContainer';
import { useLayoutEffect, useState } from 'react';

export enum RoutePaths {
  ORDER_OVERVIEW = '/admin',
  CREATE_ORDER = '/admin/create-order',
  CREATE_ORDER_LEGACY = '/admin/create-lab-report', //only for legacy purposes (url configured in triaMED)
  UPDATE_ORDER = '/admin/update-order/:id',
  ORDER_CREATED = '/admin/order-created',
  INVALID_LINK = '/patient/invalid-link',
  PATIENT_LOGIN = '/patient',
  PATIENT_RESULTS = '/patient/result',
}

const routeToComponent = [
  [RoutePaths.CREATE_ORDER, <OrderCreateContainer />],
  [RoutePaths.CREATE_ORDER_LEGACY, <OrderCreateContainer />],
  [RoutePaths.UPDATE_ORDER, <OrderUpdateContainer />],
  [RoutePaths.ORDER_CREATED, <OrderCreatedContainer />],
  [RoutePaths.PATIENT_RESULTS, <Dashboard />],
  [RoutePaths.ORDER_OVERVIEW, <OrderOverviewContainer />, true],
  [RoutePaths.INVALID_LINK, <InvalidLink />],
  [RoutePaths.PATIENT_LOGIN, <Login />],
] as const;

export const routerHistory = createBrowserHistory();

function RedirectToCreateOrder() {
  return <Navigate to={RoutePaths.CREATE_ORDER} />;
}

export default function Router({ isInternalEmployee, isAdminLoggedIn }: Props) {
  const [state, setState] = useState({
    action: routerHistory.action,
    location: routerHistory.location,
  });

  useLayoutEffect(() => routerHistory.listen(setState), []);

  const redirectToCreateOrderIfNotAuthorized = (
    component: JSX.Element,
    isForInternalEmployeeOnly?: boolean
  ): JSX.Element | undefined => {
    if (!isForInternalEmployeeOnly) {
      return component;
    }
    return !isAdminLoggedIn ? undefined : isInternalEmployee ? component : RedirectToCreateOrder();
  };

  return (
    <ReactRouter location={state.location} navigationType={state.action} navigator={routerHistory}>
      <SnackbarProvider maxSnack={5} preventDuplicate anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <main>
          <Routes>
            {routeToComponent.map(([path, component, isForInternalEmployeeOnly], key) => (
              <Route
                {...{
                  path,
                  element: redirectToCreateOrderIfNotAuthorized(component, isForInternalEmployeeOnly),
                  key,
                }}
              />
            ))}
            <Route path="*" element={<Login />} />
          </Routes>
        </main>
        <AppAlertsContainer />
      </SnackbarProvider>
    </ReactRouter>
  );
}
