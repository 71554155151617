import { RootState } from '../../store';
import { Action, Dispatch } from 'redux';
import { connect } from 'react-redux';
import UserAssignmentField from './UserAssignmentField';
import { pipe } from 'ramda';
import { requestActionBundles } from '../../store/data/action';
import { selectInternalUsers, selectIsLoadingInternalUsers } from '../../store/data/selector';
import { setAssignedEmployeeId } from '../../store/order-create/action';

const mapStateToProps = (state: RootState) => ({
  users: selectInternalUsers(state),
  isLoadingUsers: selectIsLoadingInternalUsers(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  readAllInternalUsers: pipe(requestActionBundles.readAllInternalUsers.sendRequest, dispatch),
  assignEmployeeId: pipe(setAssignedEmployeeId, dispatch),
});

type OwnProps = { disabled?: boolean; assignedEmployeeId: string | null };
type StateProps = Readonly<ReturnType<typeof mapStateToProps>>;
type DispatchProps = Readonly<ReturnType<typeof mapDispatchToProps>>;
export type Props = OwnProps & StateProps & DispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(UserAssignmentField);
