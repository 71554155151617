import { applyPipe } from 'common/apply-pipe';
import { removeRequestSuffixFromActionType } from 'common/request-action';
import { createReducer } from 'deox';
import { indexOf, map, remove } from 'ramda';
import {
  requestFailedActions,
  requestSucceededActions,
  sendRequestActions,
  setInterpretedObservation,
  setPagedOrders,
  setPatientReport,
  setUpdatedOrder,
  setUsers,
} from 'store/data/action';
import { ErrorMessage, PatientReportDTO, OrderDetailsDTO, UserDTO } from 'types/data';
import produce from 'immer';

const initialState: Readonly<{
  patientReport?: PatientReportDTO;
  pagedOrders?: readonly OrderDetailsDTO[];
  orderItemCount?: number;
  users?: readonly UserDTO[];
  requestsRunning: readonly string[];
  errors: readonly ErrorMessage[];
}> = {
  requestsRunning: [],
  errors: [],
};

const removeFirstAppearanceOfType = (x: string, xs: readonly string[]) =>
  applyPipe(indexOf(removeRequestSuffixFromActionType(x), xs), (index: number) =>
    index >= 0 ? remove(index, 1, xs) : xs
  );

export const dataReducer = createReducer(initialState, (handleAction) => [
  handleAction(setPatientReport, (state, { payload }) => ({
    ...state,
    patientReport: payload.patientReport,
  })),
  handleAction(setPagedOrders, (state, { payload }) => ({
    ...state,
    pagedOrders: payload.orders,
    orderItemCount: payload.itemCount,
  })),
  handleAction(setUpdatedOrder, (state, { payload }) => ({
    ...state,
    pagedOrders: map((order) => (order.id === payload.order.id ? payload.order : order), state.pagedOrders ?? []),
  })),
  handleAction(setInterpretedObservation, (state, { payload }) =>
    produce(state, (draft) => {
      if (state.pagedOrders) {
        draft.pagedOrders = map(
          (order) => ({
            ...order,
            observations: map(
              (observation) => (observation.id === payload.observation.id ? payload.observation : observation),
              order.observations
            ),
          }),
          state.pagedOrders
        );
      }
    })
  ),
  handleAction(setUsers, (state, { payload }) => ({
    ...state,
    users: payload.users,
  })),
  handleAction(sendRequestActions, (state, action) => ({
    ...state,
    requestsRunning: [...state.requestsRunning, removeRequestSuffixFromActionType(action.type)],
  })),
  handleAction(requestSucceededActions, (state, action) => ({
    ...state,
    requestsRunning: removeFirstAppearanceOfType(action.type, state.requestsRunning),
  })),
  handleAction(requestFailedActions, (state, { type, payload }) => ({
    ...state,
    requestsRunning: removeFirstAppearanceOfType(type, state.requestsRunning),
    errors: [...state.errors, payload.error],
  })),
]);
