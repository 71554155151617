import { RootState } from '../../store';
import { Action, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { selectIsInternalEmployee } from '../../store/admin-login/selector';
import OrderCreated from './OrderCreated';
import { clearForm } from '../../store/order-create/action';
import { pipe } from 'ramda';

const mapStateToProps = (state: RootState) => ({
  isInternalEmployee: selectIsInternalEmployee(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  clearForm: pipe(clearForm, dispatch),
});

type OwnProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
export type Props = Readonly<OwnProps & StateProps & DispatchProps>;

export default connect(mapStateToProps, mapDispatchToProps)(OrderCreated);
