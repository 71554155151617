// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from 'react';
import { classNames } from '@plasmicapp/react-web';

export type LogoutIconProps = React.ComponentProps<'svg'> & {
  title?: string;
};

export function LogoutIcon(props: LogoutIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'none'}
      viewBox={'0 0 16 16'}
      height={'1em'}
      width={'1em'}
      style={{
        fill: 'currentcolor',

        ...(style || {}),
      }}
      className={classNames('plasmic-default__svg', className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path d={'M11 12.414L15.414 8 11 3.586 9.586 5l2 2H5v2h6.586l-2 2L11 12.414z'} fill={'currentColor'}></path>

      <path d={'M12 14H3V2h9V0H2a1 1 0 00-1 1v14a1 1 0 001 1h10v-2z'} fill={'currentColor'}></path>
    </svg>
  );
}

export default LogoutIcon;
/* prettier-ignore-end */
