import { FormControl, FormGroup, FormLabel, makeStyles } from '@material-ui/core';
import InfectionCheckboxControl from './InfectionCheckboxControl';
import { useTranslation } from 'react-i18next';
import { Infection } from '../../types/common';
import { FormFieldSelectorResult } from '../../types/form';
import { map } from 'ramda';

type Props = {
  formField: FormFieldSelectorResult<{ [infection in Infection]: boolean }>;
  setValues?: (payload: { infections: readonly Infection[]; present: boolean }) => unknown;
  disabled?: boolean;
};

const STICheck = {
  translationKey: 'orderForm.STICheck',
  infections: [Infection.HIV, Infection.LUES, Infection.CHLAMYDIA, Infection.GONOCOCCUS],
};

const STICheckWithScreen = {
  translationKey: 'orderForm.STICheck',
  infections: [Infection.HIV, Infection.LUES_SCREEN, Infection.CHLAMYDIA, Infection.GONOCOCCUS],
};

const STICheckPlus = {
  translationKey: 'orderForm.STICheckPlus',
  infections: [...STICheck.infections, Infection.HEPATITIS_C],
};

const STICheckPlusWithScreen = {
  translationKey: 'orderForm.STICheckPlus',
  infections: [...STICheckWithScreen.infections, Infection.HEPATITIS_C],
};

const PrEPCheck = {
  translationKey: 'orderForm.PrEPCheck',
  infections: [...STICheck.infections, Infection.LIVER, Infection.CREATININE, Infection.EGFR],
};

const PrEPCheckWithScreen = {
  translationKey: 'orderForm.PrEPCheck',
  infections: [...STICheckWithScreen.infections, Infection.LIVER, Infection.CREATININE, Infection.EGFR],
};

const PrEPCheckWithHepatitis = {
  translationKey: 'orderForm.PrEPCheckWithHepatitis',
  infections: [...STICheckPlus.infections, Infection.LIVER, Infection.CREATININE, Infection.EGFR],
};

const PrEPCheckWithHepatitisAndScreen = {
  translationKey: 'orderForm.PrEPCheckWithHepatitis',
  infections: [...STICheckPlusWithScreen.infections, Infection.LIVER, Infection.CREATININE, Infection.EGFR],
};

const useStyles = makeStyles({
  formControl: {
    width: '100%',
  },
  formGroupContent: {
    display: 'flex',
  },
  checkBoxColumn: {
    width: '50%',
  },
});

const ChecksCheckboxGroup = ({ formField, setValues, disabled }: Props) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const checkboxes = (list: readonly { translationKey: string; infections: Infection[] }[]) =>
    map(
      (check) => (
        <InfectionCheckboxControl
          key={check.translationKey}
          elements={check.infections}
          label={t(check.translationKey)}
          fieldValues={formField.value}
          setValues={setValues}
          disabled={disabled}
        />
      ),
      list
    );

  return (
    <div className={classes.formGroupContent}>
      <FormControl className={classes.formControl}>
        <FormLabel>{t('orderForm.group')}</FormLabel>
        <FormGroup>
          <div className={classes.formGroupContent}>
            <div className={classes.checkBoxColumn}>
              {checkboxes([STICheck, STICheckPlus, PrEPCheck, PrEPCheckWithHepatitis])}
            </div>
          </div>
        </FormGroup>
      </FormControl>
      <FormControl className={classes.formControl}>
        <FormLabel>{t('orderForm.groupWithScreen')}</FormLabel>
        <FormGroup>
          <div className={classes.formGroupContent}>
            <div className={classes.checkBoxColumn}>
              {checkboxes([
                STICheckWithScreen,
                STICheckPlusWithScreen,
                PrEPCheckWithScreen,
                PrEPCheckWithHepatitisAndScreen,
              ])}
            </div>
          </div>
        </FormGroup>
      </FormControl>
    </div>
  );
};

export default ChecksCheckboxGroup;
