import { createActionCreator } from 'deox';

export const setOpenAccordion = createActionCreator(
  'OrderOverview/SET_OPEN_ACCORDION',
  (resolve) => (reportId: string | null) => resolve({ reportId })
);

export const setFilterByUser = createActionCreator(
  'OrderOverview/SET_FILTER_BY_USER',
  (resolve) => (filterByUserIsActive: boolean) => resolve({ filterByUserIsActive })
);

export const setFilterByInterpretationNeeded = createActionCreator(
  'OrderOverview/SET_FILTER_BY_INTERPRETATION_NEEDED',
  (resolve) => (filterByInterpretationNeededIsActive: boolean) => resolve({ filterByInterpretationNeededIsActive })
);

export const setFilterByPositiveInterpretation = createActionCreator(
  'OrderOverview/SET_FILTER_BY_POSITIVE_INTERPRETATION',
  (resolve) => (filterByPositiveInterpretationIsActive: boolean) => resolve({ filterByPositiveInterpretationIsActive })
);

export const deleteAllFilters = createActionCreator('OrderOverview/DELETE_FILTER');

export const setCurrentPage = createActionCreator(
  'OrderOverview/SET_CURRENT_PAGE',
  (resolve) => (page: number) => resolve({ page })
);

export const setSort = createActionCreator(
  'OrderOverview/SET_SORT',
  (resolve) => (payload: { columnId: string; direction: 'asc' | 'desc' }) => resolve(payload)
);

export const setSearchQuery = createActionCreator(
  'OrderOverview/SET_SEARCH_QUERY',
  (resolve) => (searchQuery: string) => resolve({ searchQuery })
);
