import { createActionCreator } from 'deox';

export const setPasscodeLetter = createActionCreator(
  'PatientLogin/SET_PASSCODE_LETTER',
  (resolve) => (passcodeLetter: string) => resolve({ passcodeLetter })
);

export const clearPasscodeLetter = createActionCreator(
  'PatientLogin/CLEAR_PASSCODE_LETTER',
  (resolve) => () => resolve()
);

export const setCurrentPosition = createActionCreator(
  'PatientLogin/SET_CURRENT_POSITION',
  (resolve) => (position: number) => resolve({ position })
);

export const setRelativePosition = createActionCreator(
  'PatientLogin/SET_RELATIVE_POSITION',
  (resolve) => (offset: number) => resolve({ offset })
);
