import { createActionCreator } from 'deox';
import { Infection, Language } from '../../types/common';

export const setKgNr = createActionCreator('OrderCreate/KG_NR_SET', (resolve) => (kgNr: string) => resolve({ kgNr }));

export const setPhone = createActionCreator(
  'OrderCreate/SET_PHONE',
  (resolve) => (phone: string) => resolve({ phone })
);

export const setInfections = createActionCreator(
  'OrderCreate/SET_INFECTIONS',
  (resolve) => (payload: { infections: readonly Infection[]; present: boolean }) => resolve(payload)
);

export const setLanguage = createActionCreator(
  'OrderCreate/SET_LANGUAGE',
  (resolve) => (language: Language) => resolve({ language })
);

export const setAssignedEmployeeId = createActionCreator(
  'LabReportCreate/SET_ASSIGNED_EMPLOYEE_ID',
  (resolve) => (userId: string | null) => resolve({ userId })
);

export const clearForm = createActionCreator('OrderCreate/CLEAR_FORM', (resolve) => () => resolve());
