import { call, put, takeLatest } from 'redux-saga/effects';
import { ActionType, getType } from 'deox';
import { requestActionBundles } from './action';
import { v4 as uuid } from 'uuid';
import { addAlert } from '../alert/action';
import { AlertSeverity } from '../../types/alert';
import { endpoints } from '../../common/api/api';
import { getApiClientForMethod } from '../../common/api/request-builder';
import { getAuthorizationHeader } from '../../common/api/getAuthHeader';
import { getToken } from '../../common/keycloak';
import { closeDeleteDialog } from '../order-delete/action';

function* requestSaga(action: ActionType<typeof requestActionBundles.deleteOrder.sendRequest>) {
  try {
    const { method, url } = endpoints.order.delete(action.payload.orderId);
    yield call(getApiClientForMethod(method), url, {
      headers: getAuthorizationHeader(yield call(getToken)),
    });
    yield put(requestActionBundles.deleteOrder.requestSucceeded());
    yield put(closeDeleteDialog());
    yield put(addAlert(yield call(uuid), 'orderDelete.success', AlertSeverity.SUCCESS));
    yield put(requestActionBundles.readPagedOrders.sendRequest());
  } catch (error) {
    console.log(error);
    const id: string = yield call(uuid);
    yield put(addAlert(id, 'orderDelete.error', AlertSeverity.ERROR));
    yield put(
      requestActionBundles.deleteOrder.requestFailed({
        id: yield call(uuid),
        message: JSON.stringify(error),
      })
    );
  }
}

export function* orderDeleteSaga() {
  yield takeLatest(getType(requestActionBundles.deleteOrder.sendRequest), requestSaga);
}
