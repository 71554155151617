import { call, put, select, takeLatest } from 'redux-saga/effects';
import { ActionType, getType } from 'deox';
import { requestActionBundles, setUpdatedOrder, setUpdateOrderForm } from './action';
import { endpoints } from '../../common/api/api';
import { getApiClientForMethod } from '../../common/api/request-builder';
import { getAuthorizationHeader } from '../../common/api/getAuthHeader';
import { getToken } from '../../common/keycloak';
import { v4 as uuid } from 'uuid';
import { addAlert } from '../alert/action';
import { AlertSeverity } from '../../types/alert';
import { routerHistory, RoutePaths } from '../../components/router/Router';
import { selectIsOrderUpdateFormValid, selectOrderUpdateFormValues } from '../order-update/selector';
import { OrderFormValues } from '../../types/form';
import { transformFormToDTO } from '../common';
import { UpdateOrderDTO } from '../../types/data';
import { AxiosResponse } from 'axios';
import {
  logWarningIfValidationRemarksArePresent,
  transformWithSchema,
} from '@healthinal/typescript-schema-transformer';
import { OrderDetailsDTOSchema } from '../../types/schemas';

function* updateOrderIsEnteredInPISSaga(
  action: ActionType<typeof requestActionBundles.updateOrderIsDocumentedInPIS.sendRequest>
) {
  try {
    yield call(requestSaga, action.payload.orderId, {
      phone: null,
      language: null,
      infections: null,
      isDocumentedInPIS: action.payload.isDocumentedInPIS,
      isBilled: null,
    });
    yield put(requestActionBundles.updateOrderIsDocumentedInPIS.requestSucceeded());
    yield put(addAlert(yield call(uuid), 'orderUpdate.success', AlertSeverity.SUCCESS));
  } catch (error) {
    console.log(error);
    const id: string = yield call(uuid);
    yield put(addAlert(id, 'orderUpdate.updateError', AlertSeverity.ERROR));
    yield put(
      requestActionBundles.updateOrderIsDocumentedInPIS.requestFailed({
        id,
        message: JSON.stringify(error),
      })
    );
  }
}

function* updateOrderIsBilledSaga(action: ActionType<typeof requestActionBundles.updateOrderIsBilled.sendRequest>) {
  try {
    yield call(requestSaga, action.payload.orderId, {
      phone: null,
      language: null,
      infections: null,
      isDocumentedInPIS: null,
      isBilled: action.payload.isBilled,
    });
    yield put(requestActionBundles.updateOrderIsBilled.requestSucceeded());
    yield put(addAlert(yield call(uuid), 'orderUpdate.success', AlertSeverity.SUCCESS));
  } catch (error) {
    console.log(error);
    const id: string = yield call(uuid);
    yield put(addAlert(id, 'orderUpdate.updateError', AlertSeverity.ERROR));
    yield put(
      requestActionBundles.updateOrderIsBilled.requestFailed({
        id,
        message: JSON.stringify(error),
      })
    );
  }
}

function* updateOrderWithFormDataSaga(action: ActionType<typeof setUpdateOrderForm>) {
  try {
    const isValid: boolean = yield select(selectIsOrderUpdateFormValid);
    if (!isValid) {
      yield put(
        requestActionBundles.updateOrderWithFormData.requestFailed({
          id: yield call(uuid),
          message: 'Request cannot be completed. Not all data is present in the form.',
        })
      );
      return;
    }
    const form: OrderFormValues = yield select(selectOrderUpdateFormValues);
    const { kgNr, ...formValues } = transformFormToDTO(form);
    yield call(requestSaga, action.payload.orderId, {
      ...formValues,
      isDocumentedInPIS: null,
      isBilled: null,
    });
    yield put(requestActionBundles.updateOrderWithFormData.requestSucceeded());
    yield put(addAlert(yield call(uuid), 'orderUpdate.success', AlertSeverity.SUCCESS));
    yield call(routerHistory.push, RoutePaths.ORDER_OVERVIEW);
  } catch (error) {
    console.log(error);
    const id: string = yield call(uuid);
    yield put(addAlert(id, 'orderUpdate.updateError', AlertSeverity.ERROR));
    yield put(
      requestActionBundles.updateOrderWithFormData.requestFailed({
        id,
        message: JSON.stringify(error),
      })
    );
  }
}

function* requestSaga(orderId: string, dto: UpdateOrderDTO) {
  const { method, url } = endpoints.order.update(orderId);
  const result: AxiosResponse = yield call(getApiClientForMethod(method), url, dto, {
    headers: getAuthorizationHeader(yield call(getToken)),
  });
  const [order, validationRemarks] = transformWithSchema(OrderDetailsDTOSchema, result.data);
  logWarningIfValidationRemarksArePresent('order update saga', validationRemarks);
  yield put(setUpdatedOrder(order));
}

export function* orderUpdateSaga() {
  yield takeLatest(
    getType(requestActionBundles.updateOrderIsDocumentedInPIS.sendRequest),
    updateOrderIsEnteredInPISSaga
  );
  yield takeLatest(getType(requestActionBundles.updateOrderIsBilled.sendRequest), updateOrderIsBilledSaga);
  yield takeLatest(getType(requestActionBundles.updateOrderWithFormData.sendRequest), updateOrderWithFormDataSaga);
}
