import { Action, createActionCreator } from 'deox';
import { dropLast, endsWith } from 'ramda';
import { ExactActionCreator, Executor } from 'deox/dist/create-action-creator';
import { ErrorMessage } from '../types/data';

const requestedSuffix = '_REQUESTED';
const succeededSuffix = '_SUCCEEDED';
const failedSuffix = '_FAILED';

// See deox for the type signature
export const createRequestActionsCreator: <TStorePrefix extends string>(
  storePrefix: TStorePrefix
) => {
  <
    TType extends string,
    TPrefixType extends `${TStorePrefix}/${TType}`,
    TRequestedType extends `${TPrefixType}${typeof requestedSuffix}`,
    TSucceededType extends `${TPrefixType}${typeof succeededSuffix}`,
    TFailedType extends `${TPrefixType}${typeof failedSuffix}`
  >(
    type: TType
  ): Readonly<{
    sendRequest: ExactActionCreator<TRequestedType, () => Action<TRequestedType>>;
    requestSucceeded: ExactActionCreator<TSucceededType, () => Action<TSucceededType>>;
    requestFailed: ExactActionCreator<
      TFailedType,
      (error: ErrorMessage) => Action<TFailedType, { error: ErrorMessage }>
    >;
  }>;
  <
    TType extends string,
    TPrefixType extends `${TStorePrefix}/${TType}`,
    TRequestedType extends `${TPrefixType}${typeof requestedSuffix}`,
    TSucceededType extends `${TPrefixType}${typeof succeededSuffix}`,
    TFailedType extends `${TPrefixType}${typeof failedSuffix}`,
    TCallable extends (...args: any[]) => Action<TRequestedType>
  >(
    type: TType,
    sendRequestExecutor: Executor<TRequestedType, TCallable>
  ): Readonly<{
    sendRequest: ExactActionCreator<TRequestedType, TCallable>;
    requestSucceeded: ExactActionCreator<TSucceededType, () => Action<TSucceededType>>;
    requestFailed: ExactActionCreator<
      TFailedType,
      (error: ErrorMessage) => Action<TFailedType, { error: ErrorMessage }>
    >;
  }>;
} = (storePrefix) => (type: string, sendRequestExecutor?: any) => ({
  sendRequest: createActionCreator(`${storePrefix}/${type}${requestedSuffix}`, sendRequestExecutor),
  requestSucceeded: createActionCreator(`${storePrefix}/${type}${succeededSuffix}`),
  requestFailed: createActionCreator(
    `${storePrefix}/${type}${failedSuffix}`,
    (resolve) => (error: ErrorMessage) => resolve({ error })
  ),
});

export const removeRequestSuffixFromActionType = (type: string): string =>
  endsWith(requestedSuffix, type)
    ? dropLast(requestedSuffix.length, type)
    : endsWith(succeededSuffix, type)
    ? dropLast(succeededSuffix.length, type)
    : endsWith(failedSuffix, type)
    ? dropLast(failedSuffix.length, type)
    : type;
