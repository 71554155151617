import { CircularProgress, makeStyles, TableCellProps, Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Infection, Interpretation } from '../../types/common';
import { map } from 'ramda';
import { Check, Clock, Close, Delete, Email, Pencil } from 'mdi-material-ui';
import * as React from 'react';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { generatePath, useNavigate } from 'react-router-dom';
import { StateCheckbox } from './StateCheckbox';
import TableCell from '@material-ui/core/TableCell';
import { OrderState } from '../../types/data';
import InterpretationMessageDialogContainer from '../observation-interpret/InterpretationMessageDialogContainer';
import OrderDeleteDialogContainer from '../order-delete/OrderDeleteDialogContainer';
import { Props } from './OrderDetailsContainer';
import { RoutePaths } from '../router/Router';

const useStyles = makeStyles((theme: Theme) => ({
  paddingZero: {
    padding: theme.spacing(0, 2, 0, 1.5),
  },
  table: {
    minWidth: 650,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  tableRow: {
    '&:last-child td, &:last-child th': { border: 0 },
  },
  placeholderCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  placeholderIcon: {
    marginRight: theme.spacing(2),
    display: 'flex',
  },
  select: {
    width: '70%',
    fontSize: theme.typography.pxToRem(14),
    '& .MuiSelect-select.MuiSelect-select': {
      paddingRight: 0,
    },
  },
  toBeInterpreted: {
    fontWeight: 'bold',
    color: theme.palette.error.main,
  },
  menuItem: {
    fontSize: theme.typography.pxToRem(14),
  },
  footer: {
    display: 'flex',
    margin: theme.spacing(1, 0, 2, 0),
    columnGap: theme.spacing(2),
  },
  footerLeftSide: { flexGrow: 2, display: 'flex' },
  billingColumn: { maxWidth: 170 },
  patientAccessColumn: { display: 'flex', padding: 9 },
  patientAccessDescription: { marginLeft: 8, paddingTop: 2 },
  footerRightSide: { display: 'flex', alignItems: 'end', rowGap: theme.spacing(1), flexWrap: 'wrap' },
  description: {
    marginRight: theme.spacing(1),
  },
  button: {
    marginRight: theme.spacing(1),
    '&:last-child': {
      marginRight: 0,
    },
  },
  smsButtonContent: { display: 'flex', alignItems: 'center' },
  smsButtonProgress: { display: 'flex', marginLeft: '16' },
  passCodeValue: {
    letterSpacing: theme.spacing(0.5),
  },
  cell: {
    padding: theme.spacing(0, 0, 0, 0),
  },
}));

const defineInterpretationBy = (
  interpretedByFullName: string | undefined,
  interpretation: Interpretation,
  interpretedByMachine: string
) => {
  if (interpretedByFullName) {
    return interpretedByFullName;
  }
  if (
    interpretation === Interpretation.WAITING_FOR_RESULTS ||
    interpretation === Interpretation.NEEDS_MANUAL_INTERPRETATION
  ) {
    return undefined;
  }
  return interpretedByMachine;
};

const getPossibleInterpretationStates = (infection: Infection) => {
  switch (infection) {
    case Infection.GONOCOCCUS:
    case Infection.CHLAMYDIA:
    case Infection.LUES_SCREEN:
      return [Interpretation.NEGATIVE, Interpretation.POSITIVE, Interpretation.NEEDS_MANUAL_INTERPRETATION];
    default:
      return [
        Interpretation.NEGATIVE,
        Interpretation.VALUES_INCREASED,
        Interpretation.POSITIVE,
        Interpretation.NEEDS_MANUAL_INTERPRETATION,
      ];
  }
};

export default function OrderDetails({
  order: { id, observations, passcode, isDocumentedInPIS, isBilled, patientHasAccessed, billingInfo, states },
  resendSMS,
  isResendingSMS,
  interpretObservation,
  openDeleteDialog,
  openInterpretationMessageDialog,
  setIsDocumentedInPIS,
  isUpdatingIsDocumentedInPIS,
  setIsBilled,
  isUpdatingIsBilled,
}: Props) {
  const [t] = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();

  const allInfoForPatientIsPresent =
    !states.includes(OrderState.NEEDS_MANUAL_INTERPRETATION) && !states.includes(OrderState.NOT_ALL_RESULTS_AVAILABLE);

  const Cell = (props: TableCellProps) => <TableCell className={classes.cell} {...props} />;

  return (
    <>
      <Table className={classes.table}>
        <colgroup>
          <col width={'22%'} />
          <col width={'24%'} />
          <col width={'32%'} />
          <col width={'22%'} />
        </colgroup>
        <TableHead>
          <TableRow>
            <Cell>{t('orderOverview.observationTable.infection')}</Cell>
            <Cell align="center">{t('orderOverview.observationTable.values')}</Cell>
            <Cell align="center">{t('orderOverview.observationTable.interpretation')}</Cell>
            <Cell align="right">{t('orderOverview.observationTable.interpretedBy')}</Cell>
          </TableRow>
        </TableHead>
        <TableBody>
          {observations?.map(({ id, infection, interpretation, value, interpretedByFullName }) => (
            <TableRow key={infection} className={classes.tableRow}>
              <Cell component="th" scope="row">
                {t(infection)}
              </Cell>
              <Cell align="center">{value}</Cell>
              <Cell align="center">
                {interpretation === Interpretation.WAITING_FOR_RESULTS ? (
                  <div className={classes.placeholderCell}>
                    <div className={classes.placeholderIcon}>
                      <Clock />
                    </div>
                    <div>{t(`orderOverview.observationTable.select.${interpretation}`)}</div>
                  </div>
                ) : (
                  <>
                    <Select
                      defaultValue={interpretation}
                      value={interpretation}
                      onChange={(e) => {
                        const interpretation = e.target.value as Interpretation;
                        interpretation === Interpretation.VALUES_INCREASED && infection !== Infection.HIV
                          ? openInterpretationMessageDialog({ id, infection })
                          : interpretObservation({
                              id,
                              interpretation,
                              message: null,
                            });
                      }}
                      className={clsx([
                        classes.select,
                        interpretation === Interpretation.NEEDS_MANUAL_INTERPRETATION ? classes.toBeInterpreted : '',
                      ])}
                    >
                      {map(
                        (interpretation) => (
                          <MenuItem value={interpretation} key={interpretation} className={classes.menuItem}>
                            {t(`orderOverview.observationTable.select.${interpretation}`)}
                          </MenuItem>
                        ),
                        getPossibleInterpretationStates(infection)
                      )}
                    </Select>
                  </>
                )}
              </Cell>
              <Cell align="right">
                {defineInterpretationBy(
                  interpretedByFullName,
                  interpretation,
                  t(`orderOverview.observationTable.interpretedByMachine`)
                )}
              </Cell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className={classes.footer}>
        <div className={classes.footerLeftSide}>
          <div>
            <StateCheckbox
              orderId={id}
              isChecked={isDocumentedInPIS}
              translationKey={'isDocumentedInPIS'}
              isDisabled={!allInfoForPatientIsPresent || isUpdatingIsDocumentedInPIS() || isUpdatingIsBilled()}
              onChange={(orderId, isChecked) =>
                setIsDocumentedInPIS({
                  orderId,
                  isDocumentedInPIS: isChecked,
                })
              }
              isUpdating={isUpdatingIsDocumentedInPIS}
            />
            <div>
              <span className={classes.description}>{t(`orderOverview.tableEntryDetails.codeDescription`)}:</span>
              <span className={classes.passCodeValue}>{passcode}</span>
            </div>
          </div>
          <div className={classes.billingColumn}>
            <StateCheckbox
              orderId={id}
              isChecked={isBilled}
              translationKey={'isBilled'}
              isDisabled={!allInfoForPatientIsPresent || isUpdatingIsDocumentedInPIS() || isUpdatingIsBilled()}
              onChange={(orderId, isChecked) =>
                setIsBilled({
                  orderId,
                  isBilled: isChecked,
                })
              }
              isUpdating={isUpdatingIsBilled}
            />
            {billingInfo && (
              <div>
                <span className={classes.description}>{t(`orderOverview.tableEntryDetails.billing.description`)}:</span>
                <span>{t(`orderOverview.tableEntryDetails.billing.${billingInfo}`)}</span>
              </div>
            )}
          </div>
          <div className={classes.patientAccessColumn}>
            {patientHasAccessed ? <Check /> : <Close />}
            <span className={classes.patientAccessDescription}>
              {t(
                `orderOverview.tableEntryDetails.${
                  patientHasAccessed ? 'patientHasAccessed' : 'patientHasNotAccessedYet'
                }`
              )}
            </span>
          </div>
        </div>
        <div className={classes.footerRightSide}>
          <Button
            className={classes.button}
            onClick={() => resendSMS(id)}
            disabled={isResendingSMS(id)}
            variant={'outlined'}
          >
            <div className={classes.smsButtonContent}>
              <span className={classes.description}>{t(`orderOverview.tableEntryDetails.resendSMS.button`)}</span>
              <div className={classes.smsButtonProgress}>
                {isResendingSMS(id) ? <CircularProgress size={16} /> : <Email />}
              </div>
            </div>
          </Button>
          <Button
            className={classes.button}
            onClick={() => navigate(generatePath(RoutePaths.UPDATE_ORDER, { id }))}
            variant={'outlined'}
          >
            <span className={classes.description}>{t(`orderOverview.tableEntryDetails.editButton`)}</span>
            <Pencil />
          </Button>
          <Button className={classes.button} onClick={() => openDeleteDialog(id)} variant={'outlined'}>
            <span className={classes.description}>{t(`orderOverview.tableEntryDetails.deleteButton`)}</span>
            <Delete />
          </Button>
          <InterpretationMessageDialogContainer />
          <OrderDeleteDialogContainer />
        </div>
      </div>
    </>
  );
}
