import { call, put, takeLatest } from 'redux-saga/effects';
import { ActionType, getType } from 'deox';
import { requestActionBundles, setInterpretedObservation } from './action';
import { endpoints } from '../../common/api/api';
import { AxiosResponse } from 'axios';
import { getApiClientForMethod } from '../../common/api/request-builder';
import { getAuthorizationHeader } from '../../common/api/getAuthHeader';
import { getToken } from '../../common/keycloak';
import { v4 as uuid } from 'uuid';
import { ObservationDTOSchema } from '../../types/schemas';
import {
  logWarningIfValidationRemarksArePresent,
  transformWithSchema,
} from '@healthinal/typescript-schema-transformer';
import { addAlert } from '../alert/action';
import { AlertSeverity } from '../../types/alert';
import { closeInterpretationMessageDialog } from '../observation-interpret/actions';

function* requestSaga(action: ActionType<typeof requestActionBundles.interpretObservation.sendRequest>) {
  try {
    const { method, url } = endpoints.observation.update();
    const result: AxiosResponse = yield call(getApiClientForMethod(method), url, action.payload, {
      headers: getAuthorizationHeader(yield call(getToken)),
    });
    const [payload, validationRemarks] = transformWithSchema(ObservationDTOSchema, result.data);
    logWarningIfValidationRemarksArePresent('interpret result saga', validationRemarks);
    yield put(requestActionBundles.interpretObservation.requestSucceeded());
    yield put(setInterpretedObservation(payload));
    yield put(closeInterpretationMessageDialog());
    yield put(
      addAlert(yield call(uuid), 'orderOverview.observationTable.interpretationSuccess', AlertSeverity.SUCCESS)
    );
  } catch (error) {
    console.log(error);
    const id: string = yield call(uuid);
    yield put(addAlert(id, 'orderOverview.observationTable.interpretationError', AlertSeverity.ERROR));
    yield put(
      requestActionBundles.interpretObservation.requestFailed({
        id: yield call(uuid),
        message: JSON.stringify(error),
      })
    );
  }
}

export function* observationInterpretSaga() {
  yield takeLatest(getType(requestActionBundles.interpretObservation.sendRequest), requestSaga);
}
