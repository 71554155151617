import { IconButton, makeStyles, TextField, Theme } from '@material-ui/core';
import { Close, Magnify } from 'mdi-material-ui';
import { Props } from './SearchBarContainer';
import { isEmpty } from 'ramda';

const useStyles = makeStyles((theme: Theme) => ({
  searchBar: {
    maxWidth: '550px',
    flexGrow: 1,
    margin: theme.spacing(0, 2),
  },
  input: {
    height: theme.spacing(5),
  },
  icon: {
    padding: 12,
  },
}));

export default function SearchBar({ searchQuery, setSearchQuery }: Props) {
  const classes = useStyles();

  return (
    <TextField
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
      className={classes.searchBar}
      InputProps={{
        endAdornment: !isEmpty(searchQuery) ? (
          <IconButton onClick={() => setSearchQuery('')}>
            <Close />
          </IconButton>
        ) : (
          <div className={classes.icon}>
            <Magnify />
          </div>
        ),
        className: classes.input,
      }}
    />
  );
}
