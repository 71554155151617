import { all, isEmpty, pipe } from 'ramda';
import { FormField } from 'types/form';

export const selectFormFieldErrors = <T>({ errors }: FormField<T>) => errors;

export const selectIsFormFieldValid: <T>(formField: FormField<T>) => boolean = pipe(selectFormFieldErrors, isEmpty);

export const selectShowFormFieldAsInvalid = <T>(formField: FormField<T>) =>
  selectIsFormFieldDirty(formField) ? !selectIsFormFieldValid(formField) : false;

export const selectIsFormValid: (formFields: readonly FormField<any>[]) => boolean = all(selectIsFormFieldValid);

export const selectFormFieldValue = <T>({ value }: FormField<T>) => value;
export const selectIsFormFieldDirty = <T>({ isDirty }: FormField<T>) => isDirty;

export const selectFormFieldViewValues = <T>(formField: FormField<T>) => ({
  value: selectFormFieldValue(formField),
  showAsInvalid: selectShowFormFieldAsInvalid(formField),
  errors: selectFormFieldErrors(formField),
});
