import { BillingInfo, Infection, Interpretation, Language } from './common';

export type CreateOrderDTO = Readonly<{
  kgNr: string;
  phone: string;
  language: string;
  infections: Infection[];
  assignedEmployeeId: string | null;
}>;

export type UpdateOrderDTO = Readonly<{
  phone: string | null;
  language: string | null;
  infections: Infection[] | null;
  isDocumentedInPIS: boolean | null;
  isBilled: boolean | null;
}>;

export type OrderDTO = Readonly<{
  kgNr: string;
  phone: string;
  passcode: string;
  infections: readonly Infection[];
  assignedEmployeeId: string | undefined;
}>;

export type OrderDetailsDTO = Readonly<{
  id: string;
  kgNr: string;
  created: string;
  firstName: string | undefined;
  lastName: string | undefined;
  phone: string;
  language: Language;
  billingInfo: BillingInfo | undefined;
  dateOfBirth: string | undefined;
  passcode: string;
  isDocumentedInPIS: boolean;
  isBilled: boolean;
  patientHasAccessed: boolean;
  assignedEmployeeId?: string;
  observations: readonly ObservationDTO[];
}>;

export type ObservationDTO = Readonly<{
  id: string;
  infection: Infection;
  interpretation: Interpretation;
  interpretedByFullName?: string;
  value?: string;
}>;

export enum OrderState {
  NOT_ALL_RESULTS_AVAILABLE = 'NOT_ALL_RESULTS_AVAILABLE',
  NEEDS_MANUAL_INTERPRETATION = 'NEEDS_MANUAL_INTERPRETATION',
  PATIENT_HAS_NOT_ACCESSED = 'PATIENT_HAS_NOT_ACCESSED',
  IS_NOT_DOCUMENTED_IN_PIS = 'IS_NOT_DOCUMENTED_IN_PIS',
  IS_NOT_BILLED = 'IS_NOT_BILLED',
  COMPLETED = 'COMPLETED',
}

export type OrderDetailsDTOWithStates = OrderDetailsDTO &
  Readonly<{
    states: readonly OrderState[];
  }>;

export type PatientReportDTO = Readonly<{
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
  created: string;
  results: readonly PatientResultDTO[];
}>;

export type PatientResultDTO = Readonly<{
  id: string;
  infection: Infection;
  interpretation: Interpretation;
  value?: string;
  message?: string;
}>;

export type PatientAccessDTO = Readonly<{
  accessToken: string;
  passcode: string;
}>;

export type UserDTO = Readonly<{
  id: string;
  firstName: string;
  lastName: string;
}>;

export type ErrorMessage = Readonly<{
  id: string;
  message: string;
}>;

export type ListDTO<T> = Readonly<{
  items: T[];
}>;

export type PageDTO<T> = Readonly<{
  items: T[];
  itemCount: number;
}>;
