import { OrderFormValues } from '../types/form';
import { CreateOrderDTO } from '../types/data';
import { Infection } from '../types/common';

export const extractValue = (url: string): string | undefined => url.split('=')[1];

const valuesForMultiCheckbox = (values: { [infection: string]: boolean | undefined }) =>
  Object.entries(values)
    .filter(([_, present]) => present)
    .map(([key]) => key);

export const transformFormToDTO: (form: OrderFormValues) => CreateOrderDTO = ({ infections, ...rest }) => ({
  ...rest,
  infections: valuesForMultiCheckbox(infections) as Infection[],
});
