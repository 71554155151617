import { RootState } from '../index';
import { map, pipe, values } from 'ramda';
import {
  selectFormFieldValue,
  selectFormFieldViewValues,
  selectIsFormValid,
} from '../../common/form-validation/selector';
import { FormState } from '../../types/form';

const getPatientLoginState = ({ patientReport }: RootState) => patientReport;

export const getCurrentPosition = pipe(getPatientLoginState, ({ currentPosition }) => currentPosition);

const getForm = pipe(getPatientLoginState, ({ form }) => form);

export const getPatientLoginFormState = pipe(
  getForm,
  map(selectFormFieldViewValues) as any as (form: ReturnType<typeof getForm>) => FormState<ReturnType<typeof getForm>>
);

const mapToFormFieldValues: (form: any) => { passcodeLetters: readonly string[] } = map(selectFormFieldValue) as any;

export const getLoginPatientFormValues = pipe(getForm, mapToFormFieldValues);

export const getIsLoginPatientFormValid: (rootState: RootState) => boolean = pipe(getForm, values, selectIsFormValid);
