import TextField from './TextField';
import { useTranslation } from 'react-i18next';
import { FormFieldSelectorResult } from '../../types/form';

type Props = Readonly<{
  phoneField: FormFieldSelectorResult;
  setPhone?: (value: string) => unknown;
  disabled?: boolean;
}>;

export default function PhoneField({ phoneField, setPhone, disabled }: Props) {
  const [t] = useTranslation();

  return (
    <TextField
      label={t('orderForm.phone')}
      formField={phoneField}
      setValue={setPhone}
      formType={'phone'}
      disabled={disabled}
    />
  );
}
