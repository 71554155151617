import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

type Props = {
  translationKey: string;
  onClick: () => void;
  disabled?: boolean;
};

const SecondaryButton = ({ translationKey, onClick, disabled }: Props) => {
  const [t] = useTranslation();

  return (
    <Button variant="outlined" onClick={onClick} disabled={disabled}>
      {t(translationKey)}
    </Button>
  );
};

export default SecondaryButton;
