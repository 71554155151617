import { FunctionComponent } from 'react';
import { Infection, Language } from './common';

export enum ValidationError {
  REQUIRED = 'REQUIRED',
  AT_LEAST_ONE_REQUIRED = 'AT_LEAST_ONE_REQUIRED',
  PHONE_FORMAT = 'PHONE_FORMAT',
}

export type FormFieldValidatorResult = Readonly<{
  isValid: boolean;
  error: ValidationError;
}>;

export type FormFieldValidator<T = string> = (value: T) => FormFieldValidatorResult;

export type FormField<T = string> = Readonly<{
  value: T;
  isDirty: boolean;
  errors: ValidationError[];
}>;

export type FormFieldSelectorResult<T = string> = Readonly<{
  value: T;
  showAsInvalid: boolean;
  errors: ValidationError[];
}>;

type Form = Readonly<{
  [fieldName: string]: FormField<any>;
}>;

export type FormState<T extends Form> = Readonly<
  {
    [P in keyof T]: T[P] extends FormField<infer U> ? FormFieldSelectorResult<U> : never;
  }
>;

export type FormValues<T extends Form> = Readonly<
  {
    [P in keyof T]: T[P] extends FormField<infer U> ? U : never;
  }
>;

export type FormFieldComponentProps<T, AdditionalProps = Record<string, unknown>> = Readonly<
  {
    field: FormField<T>;
    setField?: (fieldValue: T) => unknown;
    labelKey: string;
    hideError?: boolean;
  } & AdditionalProps
>;

export type FormFieldComponent<T, AdditionalProps = Record<string, unknown>> = FunctionComponent<
  FormFieldComponentProps<T, AdditionalProps>
>;

export type OrderFormValues = Readonly<{
  kgNr: string;
  phone: string;
  infections: { [infection in Infection]: boolean };
  language: Language;
  assignedEmployeeId: string | null;
}>;

export type OrderForm = Readonly<{
  kgNr: FormField;
  phone: FormField;
  infections: FormField<{ [infection in Infection]: boolean }>;
  language: FormField;
  assignedEmployeeId: FormField<string | null>;
}>;
