import { createReducer } from 'deox';
import {
  deleteAllFilters,
  setCurrentPage,
  setFilterByInterpretationNeeded,
  setFilterByPositiveInterpretation,
  setFilterByUser,
  setOpenAccordion,
  setSearchQuery,
  setSort,
} from './action';
import produce from 'immer';

export const initialState: Readonly<{
  openAccordion: string | null;
  filterByUser: boolean;
  filterByInterpretationNeeded: boolean;
  filterByPositiveInterpretation: boolean;
  currentPage: number;
  sortBy: string;
  sortDirection: 'asc' | 'desc';
  searchQuery: string;
}> = {
  openAccordion: null,
  filterByUser: false,
  filterByInterpretationNeeded: false,
  filterByPositiveInterpretation: false,
  currentPage: 0,
  sortBy: 'created',
  sortDirection: 'desc',
  searchQuery: '',
};

export const orderOverviewReducer = createReducer(initialState, (handleAction) => [
  handleAction(setOpenAccordion, (state, { payload: { reportId } }) =>
    produce(state, (draft) => {
      draft.openAccordion = reportId;
    })
  ),
  handleAction(setFilterByUser, (state, { payload: { filterByUserIsActive } }) =>
    produce(state, (draft) => {
      if (filterByUserIsActive) {
        draft.currentPage = 0;
      }
      draft.filterByUser = filterByUserIsActive;
    })
  ),
  handleAction(setFilterByInterpretationNeeded, (state, { payload: { filterByInterpretationNeededIsActive } }) =>
    produce(state, (draft) => {
      if (filterByInterpretationNeededIsActive) {
        draft.currentPage = 0;
      }
      draft.filterByInterpretationNeeded = filterByInterpretationNeededIsActive;
    })
  ),
  handleAction(setFilterByPositiveInterpretation, (state, { payload: { filterByPositiveInterpretationIsActive } }) =>
    produce(state, (draft) => {
      if (filterByPositiveInterpretationIsActive) {
        draft.currentPage = 0;
      }
      draft.filterByPositiveInterpretation = filterByPositiveInterpretationIsActive;
    })
  ),
  handleAction(deleteAllFilters, (state) =>
    produce(state, (draft) => {
      draft.filterByUser = false;
      draft.filterByInterpretationNeeded = false;
      draft.filterByPositiveInterpretation = false;
    })
  ),
  handleAction(setCurrentPage, (state, { payload: { page } }) =>
    produce(state, (draft) => {
      draft.currentPage = page;
    })
  ),
  handleAction(setSort, (state, { payload: { columnId, direction } }) =>
    produce(state, (draft) => {
      draft.sortBy = columnId;
      draft.sortDirection = direction;
    })
  ),
  handleAction(setSearchQuery, (state, { payload: { searchQuery } }) =>
    produce(state, (draft) => {
      draft.currentPage = 0;
      draft.searchQuery = searchQuery;
    })
  ),
]);
