import { RootState } from '../index';
import { isNil, pipe } from 'ramda';
import { checkIfIsInternalEmployee } from '../../common/keycloak';

const selectAdminLoginState = ({ adminLogin }: RootState) => adminLogin;

export const selectIsInternalEmployee = pipe(
  selectAdminLoginState,
  ({ roles }) => roles ?? [],
  checkIfIsInternalEmployee
);

export const selectIsAdminLoggedIn = pipe(selectAdminLoginState, ({ roles }) => !isNil(roles));
