import { Interpretation } from '../../types/common';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@material-ui/core';
import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';
import { Props } from './InterpretationMessageDialogContainer';
import React, { useCallback } from 'react';

export function InterpretationMessageDialog({
  observationId,
  infection,
  message,
  isOpen,
  isInterpretingObservation,
  setMessage,
  interpretObservation,
  closeDialog,
}: Props) {
  const [t] = useTranslation();

  /* Single line input fields set the cursor at the end of the default value.
   * If the multiline flag is set to true, it is set at the beginning.
   * This is a workaround to have consistent autofocus behaviour and set it at the end.
   */
  const inputRef = useCallback((ref: HTMLInputElement) => {
    if (ref) {
      ref.selectionStart = ref.value.length;
      ref.selectionEnd = ref.value.length;
      ref.focus();
    }
  }, []);

  return (
    <Dialog open={isOpen} onClose={() => closeDialog()}>
      <DialogTitle>{t('InterpretTextDialog.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('InterpretTextDialog.text', { infection: t(infection ?? '') })}</DialogContentText>
        <TextField
          multiline={true}
          minRows={4}
          maxRows={10}
          variant="outlined"
          defaultValue={t('InterpretTextDialog.defaultText', { infection: t(infection ?? '') })}
          inputRef={inputRef}
          fullWidth={true}
          onChange={(e) => setMessage({ message: e.target.value })}
        />
      </DialogContent>
      <DialogActions>
        <SecondaryButton translationKey="InterpretTextDialog.abort" onClick={closeDialog} />
        <PrimaryButton
          translationKey="InterpretTextDialog.confirm"
          onClick={() => {
            interpretObservation({
              id: observationId ?? '',
              interpretation: Interpretation.VALUES_INCREASED,
              message,
            });
          }}
          isLoading={isInterpretingObservation(observationId ?? '')}
        />
      </DialogActions>
    </Dialog>
  );
}
