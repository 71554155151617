import { RootState } from '../index';
import { isNil, pipe } from 'ramda';

const selectOrderOverviewOverviewState = ({ orderOverview }: RootState) => orderOverview;

export const selectFilterByUser = pipe(selectOrderOverviewOverviewState, ({ filterByUser }) => filterByUser);

export const selectFilterByInterpretationNeeded = pipe(
  selectOrderOverviewOverviewState,
  ({ filterByInterpretationNeeded }) => filterByInterpretationNeeded
);

export const selectFilterByPositiveInterpretation = pipe(
  selectOrderOverviewOverviewState,
  ({ filterByPositiveInterpretation }) => filterByPositiveInterpretation
);

export const selectCurrentPage = pipe(selectOrderOverviewOverviewState, ({ currentPage }) => currentPage);

export const selectSearchQuery = pipe(selectOrderOverviewOverviewState, ({ searchQuery }) => searchQuery);

export const selectSortBy = pipe(selectOrderOverviewOverviewState, ({ sortBy }) => sortBy);

export const selectSortDirection = pipe(selectOrderOverviewOverviewState, ({ sortDirection }) => sortDirection);

export const selectSearchIsSet = pipe(selectOrderOverviewOverviewState, ({ searchQuery }) => !isNil(searchQuery));
