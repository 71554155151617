import { Button, CircularProgress, makeStyles, Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
  progress: {
    marginLeft: theme.spacing(1),
    display: 'flex',
  },
  buttonContent: {
    display: 'flex',
    alignItems: 'center',
  },
}));

type Props = {
  translationKey: string;
  onClick: () => void;
  disabled?: boolean;
  isLoading?: boolean;
};

export const PrimaryButton = ({ translationKey, onClick, disabled, isLoading }: Props) => {
  const [t] = useTranslation();
  const classes = useStyles();

  return (
    <Button variant="contained" color="primary" onClick={onClick} disabled={disabled || isLoading}>
      <div className={classes.buttonContent}>
        <div>
          <span>{t(translationKey)}</span>
        </div>
        {isLoading && (
          <div className={classes.progress}>
            <CircularProgress size={16} />
          </div>
        )}
      </div>
    </Button>
  );
};

export default PrimaryButton;
