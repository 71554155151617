import * as React from 'react';
import {
  DefaultDashboardHeaderProps,
  PlasmicDashboardHeader,
} from '../plasmic/checkpoint_zurich/PlasmicDashboardHeader';
import { HTMLElementRefOf } from '@plasmicapp/react-web';

const DashboardHeader = (props: DefaultDashboardHeaderProps, ref: HTMLElementRefOf<'div'>) => (
  <PlasmicDashboardHeader root={{ ref }} {...props} noButton={true} />
);

export default React.forwardRef(DashboardHeader);
