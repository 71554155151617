import {
  optionalSchema,
  optionalStringSchema,
  requiredBooleanSchema,
  requiredEnumSchema,
  requiredNumberSchema,
  requiredStringSchema,
  TransformationSchema,
} from '@healthinal/typescript-schema-transformer';
import {
  OrderDTO,
  ObservationDTO,
  PageDTO,
  PatientAccessDTO,
  PatientReportDTO,
  OrderDetailsDTO,
  PatientResultDTO,
  UserDTO,
  ListDTO,
} from './data';
import { values } from 'ramda';
import { BillingInfo, Infection, Interpretation, Language } from './common';

export const ObservationDTOSchema: TransformationSchema<Readonly<ObservationDTO>> = {
  id: requiredStringSchema(),
  infection: requiredEnumSchema(values(Infection)),
  interpretation: requiredEnumSchema(values(Interpretation)),
  interpretedByFullName: optionalStringSchema(),
  value: optionalStringSchema(),
};

export const OrderDTOSchema: TransformationSchema<Readonly<OrderDTO>> = {
  kgNr: requiredStringSchema(),
  phone: requiredStringSchema(),
  passcode: requiredStringSchema(),
  infections: [requiredEnumSchema(values(Infection))],
  assignedEmployeeId: optionalStringSchema(),
};

export const OrderDetailsDTOSchema: TransformationSchema<Readonly<OrderDetailsDTO>> = {
  id: requiredStringSchema(),
  kgNr: requiredStringSchema(),
  created: requiredStringSchema(),
  firstName: optionalStringSchema(),
  lastName: optionalStringSchema(),
  phone: requiredStringSchema(),
  language: requiredEnumSchema(values(Language)),
  billingInfo: optionalSchema(requiredEnumSchema(values(BillingInfo))),
  dateOfBirth: optionalStringSchema(),
  passcode: requiredStringSchema(),
  isDocumentedInPIS: requiredBooleanSchema(),
  isBilled: requiredBooleanSchema(),
  patientHasAccessed: requiredBooleanSchema(),
  assignedEmployeeId: optionalStringSchema(),
  observations: [ObservationDTOSchema],
};

export const PatientResultDTOSchema: TransformationSchema<Readonly<PatientResultDTO>> = {
  id: requiredStringSchema(),
  infection: requiredEnumSchema(values(Infection)),
  interpretation: requiredEnumSchema(values(Interpretation)),
  message: optionalStringSchema(),
  value: optionalStringSchema(),
};

export const patientReportDTOSchema: TransformationSchema<Readonly<PatientReportDTO>> = {
  firstName: optionalStringSchema(),
  lastName: optionalStringSchema(),
  dateOfBirth: optionalStringSchema(),
  created: requiredStringSchema(),
  results: [PatientResultDTOSchema],
};

export const patientAccessDTOSchema: TransformationSchema<PatientAccessDTO> = {
  accessToken: requiredStringSchema(),
  passcode: requiredStringSchema(),
};

export const userDTOSchema: TransformationSchema<Readonly<UserDTO>> = {
  id: requiredStringSchema(),
  firstName: requiredStringSchema(),
  lastName: requiredStringSchema(),
};

const createListDTOSchema: <T>(schema: TransformationSchema<T>) => TransformationSchema<ListDTO<T>> = <T>(
  schema: TransformationSchema<T>
) => ({
  items: [schema],
});

const createPagedDTOSchema: <T>(schema: TransformationSchema<T>) => TransformationSchema<PageDTO<T>> = <T>(
  schema: TransformationSchema<T>
) => ({
  items: [schema],
  itemCount: requiredNumberSchema(),
});

export const userDTOListSchema = createListDTOSchema(userDTOSchema);

export const pagedOrderDetailsDTOSchema = createPagedDTOSchema(OrderDetailsDTOSchema);
