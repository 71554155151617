import { RootState } from '../../store';
import {
  selectInfectionsAlreadyProcessed,
  selectIsOrderUpdateFormValid,
  selectOrderUpdateFormState,
} from '../../store/order-update/selector';
import { Action, Dispatch } from 'redux';
import { setInfections, setOrderToBeUpdated, setLanguage, setPhone } from '../../store/order-update/action';
import { pipe } from 'ramda';
import { connect } from 'react-redux';
import { selectIsLoadingPagedOrders, selectIsUpdatingOrderWithFormData, selectOrder } from '../../store/data/selector';
import { addAlert } from '../../store/alert/action';
import { requestActionBundles } from '../../store/data/action';
import OrderUpdate from './OrderUpdate';

const mapStateToProps = (state: RootState) => ({
  isValid: selectIsOrderUpdateFormValid(state),
  isUpdatingOrder: (orderId: string) => selectIsUpdatingOrderWithFormData(orderId)(state),
  isLoadingPagedOrders: selectIsLoadingPagedOrders(state),
  formState: selectOrderUpdateFormState(state),
  infectionsAlreadyProcessed: selectInfectionsAlreadyProcessed(state),
  selectOrder: selectOrder(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  setOrderToBeUpdated: pipe(setOrderToBeUpdated, dispatch),
  setPhone: pipe(setPhone, dispatch),
  setInfections: pipe(setInfections, dispatch),
  setLanguage: pipe(setLanguage, dispatch),
  addAlert: pipe(addAlert, dispatch),
  updateOrder: pipe(requestActionBundles.updateOrderWithFormData.sendRequest, dispatch),
});

type OwnProps = { disabled?: boolean };
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
export type Props = Readonly<OwnProps & StateProps & DispatchProps>;

export default connect(mapStateToProps, mapDispatchToProps)(OrderUpdate);
