import * as React from 'react';
import {
  DefaultLanguageSwitcherProps,
  PlasmicLanguageSwitcher,
} from '../plasmic/checkpoint_zurich/PlasmicLanguageSwitcher';
import { HTMLElementRefOf } from '@plasmicapp/react-web';
import i18n from 'i18next';

function LanguageSwitcher(props: DefaultLanguageSwitcherProps, ref: HTMLElementRefOf<'div'>) {
  const switchLanguage = () => {
    i18n.changeLanguage(i18n.language === 'de' ? 'en' : 'de').then();
  };

  return (
    <PlasmicLanguageSwitcher
      root={{ ref }}
      {...props}
      onClick={switchLanguage}
      language={i18n.language === 'de' ? 'german' : 'english'}
    />
  );
}

export default React.forwardRef(LanguageSwitcher);
