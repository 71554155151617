import { createActionCreator } from 'deox';
import { AlertSeverity } from '../../types/alert';

export const addAlert = createActionCreator(
  'alert/ADD_ALERT',
  (resolve) => (id: string, messageKey: string, severity: AlertSeverity, duration?: number) =>
    resolve({
      id,
      messageKey,
      severity,
      duration,
    })
);

export const removeAlert = createActionCreator('alert/REMOVE_ALERT', (resolve) => (id: string) => resolve({ id }));
