type ApplyPipe = {
  <T>(x: T): T;
  <T, T1>(x: T, f1: (t: T) => T1): T1;
  <T, T1, T2>(x: T, f1: (t: T) => T1, f2: (t: T1) => T2): T2;
  <T, T1, T2, T3>(
    x: T,
    f1: (t: T) => T1,
    f2: (t: T1) => T2,
    f3: (t: T2) => T3
  ): T3;
  <T, T1, T2, T3, T4>(
    x: T,
    f1: (t: T) => T1,
    f2: (t: T1) => T2,
    f3: (t: T2) => T3,
    f4: (t: T3) => T4
  ): T4;
  <T, T1, T2, T3, T4, T5>(
    x: T,
    f1: (t: T) => T1,
    f2: (t: T1) => T2,
    f3: (t: T2) => T3,
    f4: (t: T3) => T4,
    f5: (t: T4) => T5
  ): T5;
  <T, T1, T2, T3, T4, T5, T6>(
    x: T,
    f1: (t: T) => T1,
    f2: (t: T1) => T2,
    f3: (t: T2) => T3,
    f4: (t: T3) => T4,
    f5: (t: T4) => T5,
    f6: (t: T5) => T6
  ): T6;
  <T, T1, T2, T3, T4, T5, T6, T7>(
    x: T,
    f1: (t: T) => T1,
    f2: (t: T1) => T2,
    f3: (t: T2) => T3,
    f4: (t: T3) => T4,
    f5: (t: T4) => T5,
    f6: (t: T5) => T6,
    f7: (t: T6) => T7
  ): T7;
  <T, T1, T2, T3, T4, T5, T6, T7, T8>(
    x: T,
    f1: (t: T) => T1,
    f2: (t: T1) => T2,
    f3: (t: T2) => T3,
    f4: (t: T3) => T4,
    f5: (t: T4) => T5,
    f6: (t: T5) => T6,
    f7: (t: T6) => T7,
    f8: (t: T7) => T8
  ): T8;
  <T, T1, T2, T3, T4, T5, T6, T7, T8, T9>(
    x: T,
    f1: (t: T) => T1,
    f2: (t: T1) => T2,
    f3: (t: T2) => T3,
    f4: (t: T3) => T4,
    f5: (t: T4) => T5,
    f6: (t: T5) => T6,
    f7: (t: T6) => T7,
    f8: (t: T7) => T8,
    f9: (t: T8) => T9
  ): T9;
};

export const applyPipe: ApplyPipe = (x: any, ...functions: any[]) =>
  functions.length === 0
    ? x
    : (applyPipe as any)(functions[0](x), ...functions.slice(1));
