import { Autocomplete } from '@material-ui/lab';
import { Props } from './UserAssignmentFieldContainer';
import { UserDTO } from '../../types/data';
import { TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

export default function UserAssignmentField({
  disabled,
  assignedEmployeeId,
  users,
  isLoadingUsers,
  readAllInternalUsers,
  assignEmployeeId,
}: Props) {
  const [t] = useTranslation();

  useEffect(() => {
    readAllInternalUsers();
  }, [readAllInternalUsers]);

  return (
    <Autocomplete
      loading={isLoadingUsers}
      loadingText={t('orderForm.isLoadingUsers')}
      autoHighlight={true}
      options={(users ?? []) as UserDTO[]}
      getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
      renderInput={(params) => <TextField label={t('orderForm.assignUser')} {...params} variant="outlined" />}
      disabled={disabled}
      value={users?.find((user) => user.id === assignedEmployeeId) ?? null}
      onChange={(event, selectedUser) => assignEmployeeId(selectedUser?.id ?? null)}
    />
  );
}
